import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $:any
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  currTab: any='English';
  faqData: any=[];
  pageNumber:number=1
  faqId: any;
  isPublished: any;
  lan: any;
  totalRecords: any;
  itemsPerPage: number = 10;
  question: any = ''
  constructor(public route:Router, public service:ServiceService) { }

  ngOnInit(): void {
    this.faqList()
  }
  selectTab(tab){
    this.currTab = tab;
    if(this.currTab === 'English'){
      this.faqList()
    }    
  }
  faqList(){
    let url = `static/admin/static-content/get-FAQ-subcategory-list?page=${this.pageNumber -1}&pagesize=${this.itemsPerPage}${this.question ? "&question=" + this.question : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res:any)=>{
      if(res.status==200){
        this.service.hideSpinner()
        this.faqData=res.data['List'];
        this.totalRecords = res.data.count ? res.data.List.totalElements : '';
      }

      this.service.hideSpinner()
      
    },(err)=>{
      if(err['status']==401){
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }
  isSearched: boolean = false;
  
  pagination(page) {
    this.pageNumber = page;
    if (this.isSearched) {
      this.faqList();
    } else {
      this.faqList();
    }
  }


  addFaq(tab){
    this.currTab=tab
    this.route.navigate(['/add-faq'],{queryParams:{tab:tab}})
  }

  
  editFaq(faqId, topickey){
    this.route.navigate(['/edit-faq'],{queryParams:{faqId:faqId,topickey:topickey}})
  }

  viewFaq(faqId,tab){
    this.route.navigate(['/view-faq'],{queryParams:{faqId:faqId,lan:tab}})
  }
  
  delete(faqId, tab){
    this.faqId=faqId;
    this.lan=tab
    $('#deleteModal').modal('show')
  }

  
  deleteFaqLanguage(){
    if(this.lan === 'English'){
      this.deleteUser()
    }
  }

  
  deleteUser(){
    let url="static/admin/static-content/delete-faq-subcategory-by-id?subFaqId="+this.faqId;
    let data={}
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res:any)=>{
      if(res.status==200){
        this.service.hideSpinner();
        $('#deleteModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.faqList();
      }
    },(err)=>{
      if(err['status']==401){
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }

 
  
  publish(publish,faqId,tab){
    this.faqId=faqId;
    this.isPublished=publish;
    this.lan=tab
    $('#publishModal').modal('show')

  }

 
  publishFaqLanguage(){
    if(this.lan === 'English'){
      this.publishFaq()
    }
  }

 
  publishFaq(){
    let url="static/publish-unpuqblish-faq?faqId="+this.faqId;
    let data={}
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res:any)=>{
      if(res.status==200){
        this.service.hideSpinner();
        $('#publishModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.faqList();
      }
    },(err)=>{
      if(err['status']==401){
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }

 
  edit(){}
  view(){}
  
  isAssending: boolean = true;


  sortAgent(key) {
    if (this.isAssending) {
      this.faqData = this.faqData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.faqData = this.faqData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  search() {
    this.faqData = [];
    this.totalRecords = 0
    this.pageNumber=1;
    let url =`static/admin/static-content/get-FAQ-list?page=${this.pageNumber -1}&pagesize=${this.itemsPerPage}${this.question ? "&question=" + this.question : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res:any)=>{
      if(res.status==200){
        this.faqData=res.data;
        this.totalRecords = res.data.Count ? res.data.List.totalElements : '';
         
        
      }

      this.service.hideSpinner()
      
    },(err)=>{
      if(err['status']==401){
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }

  reset() {
    this.faqData = [];
    this.totalRecords = 0
    this.pageNumber=1;
    if (this.question) {
      this.question = ''
      this.faqList()
    } else {
    }
  }
  validateForm() {
    if (
      this.question
    ) {
      return false
    }
    else {
      return true
    }

  }
}
