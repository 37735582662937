import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  id: any;
  about: any;
  dataa: any=[];
  pagekey
  constructor(public service:ServiceService, public route: ActivatedRoute,private router:Router) { }

  ngOnInit() {
    this.route.params.subscribe(x=>{

      this.pagekey= x['id'];

    })
    this.getListCode();
  }

  getListCode(){
    this.service.showSpinner();
    this.service.get('static/get-static-page-data?pageKey=ABOUT US').subscribe(res=>{
      this.service.hideSpinner();
      if(res['status']== 200){
        setTimeout(() => {
          this.dataa = res['data'];
        }, 500);
      }
    }, err=>{

      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
      }else{
   }
   })
  }
  saveAboutUS(){
   var apiReq = {
    "pageKey": this.dataa.pageKey,
    "pageData": this.dataa.pageData
  }
  this.service.showSpinner();
  this.service.post('static/update-static-content-data',apiReq).subscribe(res=>{

    this.service.hideSpinner();
    if(res['status']== 200){
      this.getListCode();
     this.router.navigate[('/statics-content')]

     this.service.toasterSucc('About Us Updated Successfully')
    }else{
      this.service.toasterErr('About Us Not Updated')
    }
  }, err=>{

    this.service.hideSpinner();
    if(err['status']=='401'){
      this.service.onLogout();
      this.service.toasterErr('Unauthorized Access');
    }else{
    this.service.toasterErr('Something Went Wrong');
 }
  })
  }
}
