import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'custom-opt-input',
  templateUrl: './custom-opt-input.component.html',
  styleUrls: ['./custom-opt-input.component.css']
})
export class CustomOptInputComponent implements OnInit, OnDestroy {
  @Output() onInputChange = new EventEmitter
  @Input() length: number = 6
  @Input() otpInput: any
  digit = []
  pasteData: any = []

  inputType = 'text'
  public arr = []

  constructor(public commonService: ServiceService) {
    this.arr = new Array(this.length)

  }

  ngOnInit(): void {
    this.commonService.isResetOtpItem.subscribe(its=>{
      if(its){
        this.digit[0] = ''
        this.digit[1] = ''
        this.digit[2] = ''
        this.digit[3] = ''
        this.digit[4] = ''
        this.digit[5] = ''
      }
    })

    setTimeout(() => {
      this.initialise_otp()
      let className: any = document.getElementsByClassName('digit-group')

      for (const iterator of className[0]) {
        document.getElementById(iterator.id).addEventListener("paste", () => {
        });
      }
      // this.onInputChange.emit('')
      this.contButton()
    }, 100);


  }

  contButton() {
    let btn = document.getElementById('btnContinue').addEventListener('click', () => {
      this.digit[0] = ''
      this.digit[1] = ''
      this.digit[2] = ''
      this.digit[3] = ''
      this.digit[4] = ''
      this.digit[5] = ''
    })
  }

  initialise_otp() {
    let me = this;
    $(".digit-group")
      .find("input")
      .each(function () {
        $(this).attr("maxlength", 1);
        $(this).on("keyup", function (e) {
          var parent = $($(this).parent());

          if (e.keyCode === 8 || e.keyCode === 37) {
            var prev = parent.find("input#" + $(this).data("previous"));

            if (prev.length) {
              $(prev).select();
            }
          } else if (
            (e.keyCode >= 48 && e.keyCode <= 57) ||
            (e.keyCode >= 65 && e.keyCode <= 90) ||
            (e.keyCode >= 96 && e.keyCode <= 105) ||
            e.keyCode === 39
          ) {
            var next = parent.find("input#" + $(this).data("next"));

            if (next.length) {
              $(next).select();
            } else {
              if (parent.data("autosubmit")) {
                parent.submit();
              }
            }
          }
        });



        $(this).on("paste", async function (e) {

          let copyval = await navigator.clipboard.readText()
          let item1 = copyval.split('')
          if (item1.length === 6) {
            me.pasteData = [...item1]
            me.digit = [...item1]
            if (me.digit[5]) {
              me.getOtp()
            }

          }
        });
      });
  }


  resetOtp() {

    // for (let index = 0; index < me.arr.length; index++) {
    //   me.digit[0] = 

    // }

    this.digit[0] = ''
    this.digit[1] = ''
    this.digit[2] = ''
    this.digit[3] = ''
    this.digit[4] = ''
    this.digit[5] = ''
    this.getOtp()
  }
  getOtp() {
    let otp_output = this.digit.toString()
    otp_output = otp_output.split(',').join('')
    this.onInputChange.emit(otp_output)
  }
  ngOnDestroy(): void {
    this.digit[0] = ''
    this.digit[1] = ''
    this.digit[2] = ''
    this.digit[3] = ''
    this.digit[4] = ''
    this.digit[5] = ''
  }

}
