import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service.service';
declare var $


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginData: any;
  Obj: any;
  ipAddress: any;
  view: boolean = false;


  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: ServiceService
  ) { }

  ngOnInit() {
    this.checkInput();
    this.getIP();
  }
  // get IP Address
  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.IPv4;
    });
  }
  regExPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i;
  checkInput() {
    let remData = JSON.parse(localStorage.getItem('rememberMe')) ? JSON.parse(localStorage.getItem('rememberMe')) : '';
    let pass = ''
    if (remData != '') {
      pass = window.atob(remData.password)
    }

    this.loginForm = this.fb.group({
      email: new FormControl(remData.email, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)),
      password: [pass, [Validators.required, Validators.pattern(this.regExPassword)]],
      rememberMe: new FormControl(false)

    })
  }
  twoFAStatus: any
  onLogin() {
    let apiReqData = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      userAgent: "ADMIN",
      location: "",
      ipAddress: this.ipAddress,
      country: 'India'
    }
    this.service.showSpinner();
    this.service.post("auth", apiReqData).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        if (this.loginForm.value.rememberMe == true) {
          let remData = {
            "email": this.loginForm.value.email,
            "password": window.btoa(this.loginForm.value.password)
          }
          localStorage.setItem('rememberMe', JSON.stringify(remData))
        }
        // localStorage.setItem('Auth', res['data']['token']);


        // localStorage.setItem('userId',res['data']['userDetails']['userId'])

        this.twoFAStatus = res['data']['TwoFa'];
        if (res['data']['TwoFa'] == 'NONE') {
          localStorage.setItem('credential', res['data']['token']);
          localStorage.setItem('authToken', res['data']['token']);
          this.service.changeLoginSub('login');
          // $('#suggest').modal({ backdrop: 'static' });
          localStorage.setItem('authToken', res['data']['token']);
          localStorage.setItem('Auth', res['data']['token']);
          // this.router.navigate(['']);
          window.location.reload()
          this.router.navigate(['/dashboard']);
        }
        else if (res['data']['TwoFa'] == 'GOOGLE') {
          localStorage.setItem('authToken', res['data']['token']);
          $('#googleAuth').modal({ backdrop: 'static' });
        } else if (res['data']['TwoFa'] == 'SMS') {
          localStorage.setItem('authToken', res['data']['token']);
          $('#smsAuth').modal({ backdrop: 'static' });
          this.suggesstionFunc('sms')
        } else if (res["data"]["TwoFa"] == "EMAIL") {
          localStorage.setItem("authToken", res["data"]["token"]);
          $("#emailAuth").modal({ backdrop: "static" });
          this.suggesstionFunc("email");
        } else if (res['data']['TwoFa'] == 'SKIP') {
          this.service.changeLoginSub('login');
          // $('#suggest').modal({ backdrop: 'static' });
          localStorage.setItem('authToken', res['data']['token']);
          localStorage.setItem('Auth', res['data']['token']);
          // this.router.navigate(['']);
          window.location.reload()
          this.router.navigate(['/dashboard']);
        }


        // this.service.changeLoginSub('login');
        // window.location.reload()

        // this.router.navigate(['dashboard']);
      }
    },
      (err: any) => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          this.service.toasterErr('Incorrect Password.');
        } else {
          this.service.toasterErr('Something went wrong.');
        }
      })
  }

  viewPassword(type) {
    if (type == 'lock') {
      this.view = true
    } else (
      this.view = false
    )
  }


  qrCode: any
  secretKey: any
  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    if (action == 'Skip') {
      this.service.showSpinner();
      this.service.get('account/skip-twoFa').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.service.toasterSucc('Logged In');
          $('#suggest').modal('hide');
          this.service.changeLoginSub('login');
          let credential = localStorage.getItem('authToken')
          localStorage.removeItem('authToken')
          localStorage.setItem('credential', credential);
          // this.router.navigate(['']);
          window.location.reload()
          this.router.navigate(['/dashboard']);
        }
        else {
          this.service.toasterErr(res['message'])
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
    else if (action == 'Google') {
      if (this.twoFAStatus == 'GOOGLE') {
        $('#suggest').modal('hide');
        $('#googleAuth').modal('show');
      } else {
        this.service.showSpinner();
        this.service.get('account/google-auth').subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            this.qrCode = res['data']['qrCode'];
            this.secretKey = res['data']['secretKey'];
            $('#suggest').modal('hide');
            $('#googleAuth').modal('show');
          }
        }, err => {
          this.service.hideSpinner();
        })
      }
    }
    else if (action == 'sms') {
      if (this.twoFAStatus == 'SMS') {
        this.service.showSpinner();
        this.service.get('auth/send-sms-code').subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            $('#suggest').modal('hide');
            $('#smsAuth').modal('show');
            this.service.toasterSucc(res['message']);
          }
          else {
            this.service.toasterErr(res['message']);
          }
        }, err => {
          this.service.hideSpinner();
          if (err['status'] == '500') {
            this.service.toasterErr('Mobile number is not verified');
          }
          else {
            this.service.toasterErr(err['message']);
          }
        })
      } else {
        this.service.showSpinner();
        this.service.get('account/send-sms-code').subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            $('#suggest').modal('hide');
            $('#smsAuth').modal('show');
            this.service.toasterSucc(res['message']);
          }
          else {
            this.service.toasterErr(res['message']);
          }
        }, err => {
          this.service.hideSpinner();
          if (err['status'] == '500') {
            this.service.toasterErr('Mobile number is not verified');
          }
          else {
            this.service.toasterErr(err['message']);
          }
        })
      }
    }
    // end email 2fa
    else if (action == "email") {
      let url =
        "account/forget-password-mobile-app?email=" +
        this.loginForm.value.email;
      if (this.twoFAStatus == "EMAIL") {
        this.service.showSpinner();
        this.service.get(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#suggest").modal("hide");
              $("#emailAuth").modal("show");
              this.service.toasterSucc(
                "An OTP has been sent on your entered email ID"
              );
            } else {
              this.service.toasterErr(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.service.toasterErr("Email is not verified");
            } else {
              this.service.toasterErr(err["message"]);
            }
          }
        );
      } else {
        this.service.showSpinner();
        this.service.get(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#suggest").modal("hide");
              $("#emailAuth").modal("show");
              this.service.toasterSucc(res["message"]);
            } else {
              this.service.toasterErr(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.service.toasterErr("Email  is not verified");
            } else {
              this.service.toasterErr(err["message"]);
            }
          }
        );
      }
    }
  }
  googleAuth: any
  emailAuth: any
  smsAuth: any
  // Verify Google / SMS Auth Functionality By Accounts
  verifyGoogleAuth(select) {
    if (select == 'Google') {
      var apireq = {};
      var url = '';
      if (this.twoFAStatus == 'GOOGLE') {
        url = 'auth/verify-google';
        apireq = {
          "otp": this.googleAuth,
          //"secretKey": this.secretKey
        }
      } else {
        url = 'account/verify-google-code';
        apireq = {
          "code": Number(this.googleAuth),
          "secretKey": this.secretKey,
          "ipAddress": this.ipAddress,
          "source": "WEB"
        }
      }
      this.service.showSpinner();
      this.service.post(url, apireq).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.emailAuth = ""
          localStorage.removeItem('authToken')
          if (this.twoFAStatus == 'GOOGLE') {
            this.service.changeLoginSub('login');
            localStorage.setItem('Auth', res['data']);
            sessionStorage.setItem('secretKey', this.secretKey);
          }
          this.service.toasterSucc('Google Authentication Verified');
          $('#googleAuth').modal('hide');
          // this.router.navigate(['']);
          window.location.reload()
          this.router.navigate(['/dashboard']);
        }
        else {
          this.service.toasterErr('Wrong Google Authentication Code');
          this.googleAuth = ''
          this.emailAuth = ""
        }
      }, err => {
        this.emailAuth = ""
        this.service.hideSpinner();
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      })
    }

    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        // url = 'account/verify-Email-Sms-code';
        url = `auth/verify-Email-code`;
        emailapireq = {
          otp: this.emailAuth,
        };
      } else {
        url = "auth/verify-Email-code";
        emailapireq = {
          otp: this.emailAuth,
          // 'emailOtp':this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.showSpinner();
      this.service.post(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.emailAuth = ""
            $("#emailAuth").modal("hide");
            this.service.changeLoginSub("login");
            //localStorage.removeItem('authToken')
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("Auth", res["data"]);
            this.service.toasterSucc(res["message"]);
            window.location.reload()
            this.router.navigate(['/dashboard']);
          } else {
            this.service.toasterErr(res["message"]);
            this.emailAuth = ""
          }
        },
        (err) => {
          this.emailAuth = ""
          this.service.hideSpinner();
          this.service.toasterErr("Please wait for few minutes & reload the site.");
        }
      );
    }
  }
  getOtp(e) {
    this.emailAuth = e
    this.verifyGoogleAuth('email')
  }
  getOtpSMS(e) {
    this.smsAuth = e
    this.verifyGoogleAuth('sms')
  }
  getOtpGoogle(e) {
    this.googleAuth = e
    this.verifyGoogleAuth('Google')
  }
}
