import { Component, OnInit } from '@angular/core';

import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-min-withdrawl-amount',
  templateUrl: './min-withdrawl-amount.component.html',
  styleUrls: ['./min-withdrawl-amount.component.css']
})
export class MinWithdrawlAmountComponent implements OnInit {
  coinList: any=[];
  pageNumber=1;
  cointAmount : any = []
  withdrawAmount : any = []


  constructor(public service:ServiceService) { }

  ngOnInit(): void {
    this.withdrawlFeeList()
  }
 
  withdrawlFeeList(){
    this.service.get(`wallet/coin/get-coin-list`).subscribe((res:any)=>{
     this.coinList=res.data
     res.data.forEach((element,index) => {
        this.cointAmount[index] = false 
      });
    })
  }
  enableInputField(i){
    this.cointAmount.forEach((ele,index)=>{
      if(index == i){
        this.cointAmount[index] = true
      }
      else{
        this.cointAmount[index] = false
      }
    })
  }

  updateCoinAmount(coinName,coinAmount){
    
   
    if(coinAmount == null || !String(coinAmount).length){
      
    
        this.service.toasterErr(`Please enter minimum withdraw amount of ${coinName}`);

        return
      
    }
      
      this.service.get(`wallet/admin/fee-management/set-minimum-withdrawal-amount?coinName=${coinName}&withdrawalAmount=${coinAmount ? coinAmount : 0}`).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message);
        this.withdrawlFeeList()
      }
    })
  }
}
