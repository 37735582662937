import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-tds-management',
  templateUrl: './tds-management.component.html',
  styleUrls: ['./tds-management.component.css']
})
export class TdsManagementComponent implements OnInit {

  userForm: FormGroup;
  ticketList: any = [];
  page: number = 0;
  limit: number = 10;
  itemsPerPage: number = 10;
  referralLimit: any
  totalRecords: any;
  pageNumber: number = 1;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']
  updatestatus: string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().slice(0, 16)

  constructor(private router: Router, public service: ServiceService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'status': new FormControl(''),

    })
    this.getTds();
    this.userTdsList();
  }


  tdsData: any
  addTds() {
    let url = `wallet/wallet/add-tds-percentage?tdsId=1`
    let data = {
      tdsPercentage: this.referralLimit
    }
    this.service.post(url, data).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.referralLimit = ''
        this.getTds();
        this.service.hideSpinner();
        this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }
  planData: any
  getTds() {
    let url = `wallet/wallet/get-tds-percentage`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.tdsData = res['data'][0].tdsPercentage
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }
  usersDetils: any
  total: any
  userTdsList() {
    let from = Date.parse(this.userForm.controls.startdate.value);
    let to = Date.parse(this.userForm.controls.enddate.value);
    let url = `wallet/admin/transaction-history/get-all-transaction-history?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.userForm.controls.status.value ? "&txnType=" + this.userForm.controls.status.value : ""}${this.userForm.controls.startdate.value ? "&fromDate=" + from : ""}${this.userForm.controls.enddate.value ? "&toDate=" + to : ""}`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.usersDetils = res['data']['resultlist']
        this.total = res['data']['totalCount']
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }



  isSearched: boolean = false;

  pagination(page) {
    this.pageNumber = page;
    this.userTdsList();
  }


  reset() {
    if (
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.status
    ) {
      this.userForm.reset({
        startdate: "",
        enddate: "",
        searchText: "",
        status: "",
      });
      this.userTdsList();
    }
  }

  viewTicket() {
    this.router.navigate(['/view-ticket'])
  }
  sortAgent(key) {
    if (this.isAssending) {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

}
