import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'app-contactus-management',
  templateUrl: './contactus-management.component.html',
  styleUrls: ['./contactus-management.component.css']
})
export class ContactusManagementComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;

  contactUsId: any;
  today: any = new Date().toISOString().slice(0, 16)

  userStatus: any = 'ACTIVE';
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getEmbellishmentList();
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  


  selectStatus() {
    this.currentPage = 1
  }
  
  getEmbellishmentList() {
    let apiReqUrl: any = `static/get-contact-us-details?page=${this.currentPage -1}&pageSize=${this.itemPerPage}`
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : '';
        this.totalItems = res.data.Count
        this.commonService.hideSpinner();
       
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },(err)=>{
      if(err.status == 404){
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
      
    })
  }

  pagination(page) {
    this.currentPage = page;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/add-announcement'])
  }
  
  viewBody(contactUsId) {
    this.router.navigate(['/contactus-view'], { queryParams: { contactUsId: contactUsId } })
  }
 
  
  editBody(contactUsId) {
    this.router.navigate(['/edit-announcement'], { queryParams: { contactUsId: contactUsId } })
  }
  
  deleteCategoryModal(contactUsId) {
    $('#deleteCategory').modal('show')
    this.contactUsId = contactUsId
  }
  deleteCategory() {
    let apiReqUrl: any = "static/delete-announcement?contactUsId="+this.contactUsId
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }


  forSerachValue(){
    let fromDate = Date.parse(this.searchForm.value.fromDate);
    let toDate = Date.parse(this.searchForm.value.toDate);
  
  let url = `static/get-contact-us-details?page=${this.currentPage -1}&pageSize=${this.itemPerPage}${this.searchForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.searchForm.value.toDate ? "&toDate=" + toDate : ""}`
   this.commonService.showSpinner();
   this.commonService.get(url).subscribe(
     (res: any) => {
       if (res.status == 200) {
        this.bodyListArray = res.data.List ? res.data.List : '';
      }
      this.totalItems = res.data.Count
    
       this.commonService.hideSpinner();
     },
     (err: any) => {
       this.commonService.hideSpinner();
       this.commonService.toasterErr(err.error.message);
     }
   );
  }
  
  reset() {
    if (
      this.searchForm.value.fromDate ||
      this.searchForm.value.toDate 
     
  
    ) {
      this.searchForm.reset({
        fromDate: "",
        toDate: "",
       
  
  
      });
      this.getEmbellishmentList();
    }
  }
}
