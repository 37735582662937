import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trading-fee',
  templateUrl: './trading-fee.component.html',
  styleUrls: ['./trading-fee.component.css']
})
export class TradingFeeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
