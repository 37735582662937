import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-aip-detail',
  templateUrl: './edit-aip-detail.component.html',
  styleUrls: ['./edit-aip-detail.component.css']
})
export class EditAipDetailComponent implements OnInit {

  contentId: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  planId : any 
  planName : any
  description : any
  totalLeft: number = 0;
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService,private router : Router) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.planId = res.planId;
    })

   }

  ngOnInit() {
    this.viewAIP();
    this.getCoinList()
  }
  coinList: any = []
  getCoinList() {
    let url = `wallet/coin/get-coin-list`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.coinList = res.data
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  update(item){
    let url = `wallet/update-auto-invest-coin-percentage-details?aipCoinId=${item.aipCoinId}`
    let data = {
      coinName : item.coinName,
      percentage : item.percentage
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url,data).subscribe((res: any) => {
      if (res.status == 200) {
        item.isUpdate = false
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  planDetail : any 
  viewAIP() {
    this.commonService.showSpinner()
    this.commonService.get("wallet/view-auto-invest-full-details-plan-id?planId=" + this.planId).subscribe((res: any) => {
      if (res.status = 200) {
        this.planDetail = res.data[0]
        this.planName = this.planDetail.planName
        this.description = this.planDetail.description
        res.data.forEach(element => {
          this.totalLeft = this.totalLeft + element.percentage
        });
        this.editData = res.data;
        this.commonService.hideSpinner()
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
updatePlanDetail() {
  let url = `wallet/update-auto-invest?planId=${this.planId}`
  let data = {
    planName : this.planName,
    description : this.description
  }
  this.commonService.showSpinner()
  this.commonService.post(url,data).subscribe((res: any) => {
    if (res.status = 200) {
     this.router.navigate(['/list-aip'])
      this.commonService.hideSpinner()

    }
  }, err => {

    this.commonService.hideSpinner();
    if (err['status'] == '401') {
      this.commonService.toasterErr('Unauthorized Access');
    } else {
      this.commonService.toasterErr('Something Went Wrong');
    }
  })
}
  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.get("static/admin/static-content/get-Blog-by-id?blogId=" + this.contentId).subscribe((res: any) => {
      if (res.status = 200) {
        this.editData = res.data;
        this.commonService.hideSpinner()

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }
  addMoreCoins(){
    this.router.navigate(['/add-aip-percent'],{queryParams : {planId : this.planId,flag : true,max : 100 - this.totalLeft}})
  }

}
