import { Component, OnInit } from '@angular/core';

import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-standard-trading-fee',
  templateUrl: './standard-trading-fee.component.html',
  styleUrls: ['./standard-trading-fee.component.css']
})
export class StandardTradingFeeComponent implements OnInit {
  coinList: any = [];
  pageNumber = 1;
  cointAmount: any = []
  withdrawAmount: any = []
  constructor(public service: ServiceService) { }

  ngOnInit(): void {
    this.tradingFeeList()
  }

  tradingFeeList() {
    this.service.showSpinner()
    this.service.get(`wallet/coin/get-coin-list`).subscribe((res: any) => {
      this.coinList = res.data
      res.data.forEach((element, index) => {
        this.cointAmount[index] = false
      });
      this.service.hideSpinner()
    })
  }
  enableInputField(i) {
    this.cointAmount.forEach((ele, index) => {
      if (index == i) {
        this.cointAmount[index] = true
      }
      else {
        this.cointAmount[index] = false
      }
    })
  }

  updateCoinAmount(coinName, coinAmount) {
    if (coinAmount == null || !String(coinAmount).length) {


      this.service.toasterErr(`Please enter minimum withdraw fee of ${coinName}`);

      return

    }
    this.service.get(`wallet/admin/fee-management/set-withdrawal-fee?coinName=${coinName}&withdrawalFee=${coinAmount ? coinAmount : 0}`).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message);
        this.tradingFeeList()
      }
    })
  }
}
