import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-referal-add',
  templateUrl: './referal-add.component.html',
  styleUrls: ['./referal-add.component.css']
})
export class ReferalAddComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService,private httpClient:HttpClient) { }

  ngOnInit() {
    this.addSwatchesFormValidation();

  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'phaseOneLimit': new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
      'phaseTwoLimit': new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
      'phaseonetoken': new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
      'phasetwotoken': new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
    })
  }





  addSwatches() {
    let url = `account/admin/user-management/admin-referal-add`
    let data = {
      'limit': this.addSwatchesForm.value.phaseOneLimit,
      'limit2': this.addSwatchesForm.value.phaseTwoLimit,
      'referalAmountRegister': this.addSwatchesForm.value.phaseonetoken,
      'referalAmountRegisterPhase2': this.addSwatchesForm.value.phasetwotoken,
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.hideSpinner();
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/referal-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },(err)=>{
this.commonService.hideSpinner()
    })
  }

  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  dataArray:any
  checkWalletAddress($evant){
    let url =`https://api.pancakeswap.info/api/v2/tokens/${this.addSwatchesForm.value.contractAddress}`
    this.httpClient.get(url).subscribe(res=>{      
      this.dataArray = res['data']
      this.addSwatchesForm.patchValue({
        coinShortName : this.dataArray.symbol,
        coinFullName: this.dataArray.name
      })
    }) 
  }


  storageUpdate(coin) {
    let data = {
        "coinName": coin,
        "storageType": 'HOT'
    }
    this.commonService.showSpinner();
    this.commonService.post('wallet/admin/hot-cold-storage/create-storage-wallet', data).subscribe((res) => {
        this.commonService.hideSpinner();
        if (res['status'] == 200) {
            this.commonService.toasterSucc(res['message'])
            this.newAddressGenerate(coin)
        }
        else {
            this.commonService.toasterErr(res['message']);
            this.commonService.hideSpinner();
        }
    }, (err) => {
        this.commonService.hideSpinner();
    })
}

newAddressGenerate(coin){
let data = {
    "accName": 1,
    "coinName": coin,
    "storageType": 'HOT'
}
this.commonService.showSpinner();
this.commonService.post('wallet/admin/hot-cold-storage/get-new-storage-address', data).subscribe((res) => {
    this.commonService.hideSpinner();
    if (res['status'] == 200) {
        this.commonService.toasterSucc(res['message'])
    }
    else {
        this.commonService.toasterErr(res['message']);
        this.commonService.hideSpinner();
    }
}, (err) => {
    this.commonService.hideSpinner();
})
}
}
