import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-commisiion-management-list',
  templateUrl: './commisiion-management-list.component.html',
  styleUrls: ['./commisiion-management-list.component.css']
})
export class CommisiionManagementListComponent implements OnInit {
  coinCommissionList: any = []

  constructor(public service: ServiceService, private datePipe: DatePipe, private router: Router) { }

  ngOnInit() {
    this.getWalletList()
  }

  getWalletList() {
    let apiReqUrl = 'wallet/coin/get-admin-commission-amounts'
    this.service.showSpinner();
    this.service.get(apiReqUrl).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.coinCommissionList = res['data']
      } else {
        this.coinCommissionList = ''
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.coinCommissionList = ''
      this.service.toasterErr(err['message'])
    })
  }

  navigateToTransactionHistory() {
    this.router.navigate(['/commission-transaction-history'])
  }
}
