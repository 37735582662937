import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-career-category-view',
  templateUrl: './career-category-view.component.html',
  styleUrls: ['./career-category-view.component.css']
})
export class CareerCategoryViewComponent implements OnInit {
  teamId: any;
  skirtListArray: any;
  colorList : any = [];
  editData:any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.teamId = res.teamId;
    })
    this.viewBlog()
  }

 viewBlog(){
  this.commonService.showSpinner()
this.commonService.get("static/get-details-by-teamId?teamId="+this.teamId).subscribe((res:any)=>{
  if (res.status=200) {
    this.editData=res.data[0];
    this.commonService.hideSpinner()

  }
 },err=>{

   this.commonService.hideSpinner();
   if(err['status']=='401'){
     this.commonService.toasterErr('Unauthorized Access');
   }else{
   this.commonService.toasterErr('Something Went Wrong');
}
 })

} 

}
