import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-fee',
  templateUrl: './manage-fee.component.html',
  styleUrls: ['./manage-fee.component.css']
})
export class ManageFeeComponent implements OnInit {
  currTab : any = ""
  constructor() { }
  tradingDetail : any = []
  ngOnInit(): void {
    this.selectTab('MINIMUM_WITHDRAW')
  }
  selectTab(tab){
    this.currTab = tab
  }

}
