import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-admin-bank-list',
  templateUrl: './admin-bank-list.component.html',
  styleUrls: ['./admin-bank-list.component.css']
})
export class AdminBankListComponent implements OnInit {
  staffForm: FormGroup
  currTab: any = 'HOT'
  itemPerPage: any = 10
  pageNumber: any = 1
  checkData: any
  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.adminFund()
  }

  fundDataArray: any = []
  adminFund() {
    let url = `wallet/admin/bank-List-admin`
    this.service.get(url).subscribe((res) => {

      if (res['status'] == 200) {
        this.fundDataArray = res['data']
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    })
  }

  selectTab(tab) {
    this.currTab = tab;
    if (tab == 'HOT') {
      this.adminFund()
    } else {
      this.getUpiData()
    }
  }
  upiDataArray: any = []
  getUpiData() {
    let url = `wallet/admin/upi-List-admin`
    this.service.get(url).subscribe((res) => {
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.upiDataArray = res['data']
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    })
  }
  changeBankStatus(bankId) {
    let url = `wallet/admin/bank-status?bankId=${String(bankId)}`
    this.service.showSpinner()
    this.service.postApi(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.adminFund()
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err['message'])
    })
  }
  changeUpiStatus(upiId) {
    let url = `wallet/admin/upi-status?upiId=${String(upiId)}`
    this.service.showSpinner()
    this.service.postApi(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.getUpiData()
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err['message'])
    })
  }
}
