import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-markup-setting',
  templateUrl: './markup-setting.component.html',
  styleUrls: ['./markup-setting.component.css']
})
export class MarkupSettingComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  coinShortName: any
  instantBuySellFee: any
  instantBuySellMaxAmt: any
  instantBuySellMinAmt: any
  constructor(private router: Router, public commonService: ServiceService, private httpClient: HttpClient, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(res => {
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getMarkUpDetails();
  }

  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'markDownBuy': new FormControl(''),
      'markDownBuyIsEnable': new FormControl(false),
      'markDownBuyStatus': new FormControl(''),
      'markDownSell': new FormControl(''),
      'markDownSellIsEnable': new FormControl(false),
      'markDownSellStatus': new FormControl(''),
      'markUpBuy': new FormControl(''),
      'markUpBuyIsEnable': new FormControl(false),
      'markUpSell': new FormControl(''),
      'markUpSellIsEnable': new FormControl(false),
    })
  }

  markUpDownDetails: any
  getMarkUpDetails() {
    let url = `wallet/coin/get-markdata`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.markUpDownDetails = res.data[0]
        this.commonService.hideSpinner();
        this.addSwatchesForm.patchValue({
          "markDownBuy": this.markUpDownDetails.markDownBuy,
      "markDownBuyIsEnable": this.markUpDownDetails.markDownBuyIsEnable,
      "markDownBuyStatus": this.markUpDownDetails.markDownBuyStatus,
      "markDownSell": this.markUpDownDetails.markDownSell,
      "markDownSellIsEnable": this.markUpDownDetails.markDownSellIsEnable,
      "markDownSellStatus": this.markUpDownDetails.markDownSellStatus,
      "markUpBuy": this.markUpDownDetails.markUpBuy,
      "markUpBuyIsEnable": this.markUpDownDetails.markUpBuyIsEnable,
      "markUpSell": this.markUpDownDetails.markUpSell,
      "markUpSellIsEnable": this.markUpDownDetails.markUpSellIsEnable,
        })
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.message)
    })
  }




  addMarkupFee() {
    let url = 'wallet/coin/mark(up/down)'
    let data = {
      "markDownBuy": this.addSwatchesForm.value.markDownBuy,
      "markDownBuyIsEnable": this.addSwatchesForm.value.markDownBuyIsEnable,
      "markDownBuyStatus": this.addSwatchesForm.value.markDownBuyStatus,
      "markDownSell": this.addSwatchesForm.value.markDownSell,
      "markDownSellIsEnable": this.addSwatchesForm.value.markDownSellIsEnable,
      "markDownSellStatus": this.addSwatchesForm.value.markDownSellStatus,
      "markUpBuy": this.addSwatchesForm.value.markUpBuy,
      "markUpBuyIsEnable": this.addSwatchesForm.value.markUpBuyIsEnable,
      "markUpSell": this.addSwatchesForm.value.markUpSell,
      "markUpSellIsEnable": this.addSwatchesForm.value.markUpSellIsEnable,
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.hideSpinner();
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/instant-buy-sell-history'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.message)
    })
  }
  updateValue(isBuy,isMarkup){
    if(isBuy){
      if(isMarkup){
        this.addSwatchesForm.patchValue({
          markDownBuyIsEnable : false
        })
      }
      else{
        this.addSwatchesForm.patchValue({
          markUpBuyIsEnable : false
        })
      }
    }
    else{
      if(isMarkup){
        this.addSwatchesForm.patchValue({
          markDownSellIsEnable : false
        })
      }
      else{
        this.addSwatchesForm.patchValue({
          markUpSellIsEnable : false
        })
      }
    }
  }

}
