import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';

declare var $: any

@Component({
  selector: 'app-order-book-list',
  templateUrl: './order-book-list.component.html',
  styleUrls: ['./order-book-list.component.css']
})
export class OrderBookListComponent implements OnInit {
  pageNumber: number = 1;
  totalRecord: any;
  pageSize: number = 20
  totalItems: any;
  fromDate: any = ''
  beDisable: boolean = true;
  userForm: FormGroup;
  userID: any;
  IsToDate: boolean = true;
  calender: any = { todate: '', formdate: '' }
  action: any;
  userList: any = [];
  searchText: string = ''
  userDetail: any = {};
  space: string = ' ';
  convertFormat: any;
  newArray: any = [];
  newFromDate: number;
  userTotalCount:any=[]
  newToDate: any;
  countryList: any = []

  constructor(
    private router: Router, public service: ServiceService
  ) {

  }

  ngOnInit() {
    this.getUserList();
  }

  // Get List of User
  getUserList() {
    var url = `order/admin/get-liquidity-platform-list`;
    // var url = "account/admin/user-management/filter-user-details?&page=1";
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userList = res['data'][0];
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

}
