import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-coin-description',
  templateUrl: './coin-description.component.html',
  styleUrls: ['./coin-description.component.css']
})
export class CoinDescriptionComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getCoinWalletList()

  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'coinName': new FormControl('', [Validators.required]),
      'description': new FormControl('', [Validators.required]),
    })
  }

  coinlist:any = []
  getCoinWalletList() {
    this.commonService.showSpinner();
    this.commonService.get("wallet/coin/get-coin-list-isVisible").subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.coinlist = res.data;

          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      },
      (error) => {
        this.commonService.hideSpinner();
      }
    );
  }


  addSwatches() {
    let url = `wallet/coin/add-description-coin?coinShortName=${this.addSwatchesForm.value.coinName}`
    let data = {
      'description': this.addSwatchesForm.value.description,
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner()
        this.router.navigate(['/statics-content'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },(err)=>{
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.message)
    })
  }
  data:any
  getCoinDeatilss(event){
this.data = event.target.value
    this.getCoinDeatils()
  }
  coinlistData:any
  getCoinDeatils(){
    let url = `wallet/coin/get-description-coin?coinShortName=${this.data}`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.coinlistData = res.data
          this.addSwatchesForm.patchValue({
            description : res.data.description
          })
          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      },
      (error) => {
        this.commonService.hideSpinner();
      }
    );
  }
}
