import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-view-blog',
  templateUrl: './view-blog.component.html',
  styleUrls: ['./view-blog.component.css']
})
export class ViewBlogComponent implements OnInit {
  contentId: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.commonService.blogData.subscribe((res)=>{
      if(!Object.keys(res).length){
        this.router.navigate(['/list-blog'])
        return
      }
      this.editData = res
    })
  }

  viewBlog() {
    
  }
}
