import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.css']
})
export class NewsEditComponent implements OnInit {
  tags = [{ tag: '',newsTagId : '' }]
  coinsPercentage = [{ coinName: '', changeSign: '+', changePercentage: '' }]
  coinList: any
  imageUrl: any
  formNews: any = {
    title: '',
    subTitle: '',
    type: '',
    description: '',
    image : ''
  }
  newsId: any
  constructor(public service: ServiceService, public activated: ActivatedRoute, private router: Router) {
    this.activated.queryParams.subscribe((res) => {
      this.newsId = res.id
    })
  }

  ngOnInit() {
    this.faqList()
  }
  getCoinList() {
    this.service.showSpinner();

    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinList = res.data

      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
      } else {
      }
    })
  }
  getNewsDetail(id?) {
    let url = `static/get-news-by-id?newsLetterStaticId=${id || this.newsId}`

    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      if (res.status == 200) {
        this.service.hideSpinner()
        let obj = res.data
        obj['type'] = res.data.newsId
        this.formNews = res.data
        this.tags = res.data.newsTag


      }

      this.service.hideSpinner()

    }, (err) => {

      this.service.hideSpinner()
      this.service.toasterErr('Something Went Wrong');

    })
  }
  faqData: any = []
  faqList() {
    let url = `static/get-all-news-details`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        this.faqData = res.data;

        this.getNewsDetail()

      }

      this.service.hideSpinner()

    }, (err) => {
      if (err['status'] == 401) {
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  addTag(tagname) {
    if (!tagname) {
      this.service.toasterErr('Please Enter Tag Name')
    }
    else {
      this.tags.push({ tag: '',newsTagId : '' })
    }

  }
  removeTag(i) {
    this.tags.splice(i, 1)
  }
  addCoinsPercentage(coinName, percentage) {
    if (!coinName) {
      this.service.toasterErr('Please Select Coin Name')
      return
    }
    if (!percentage) {
      this.service.toasterErr('Please Enter Percentage')
      return
    }


    this.coinsPercentage.push({ coinName: '', changeSign: '+', changePercentage: '' })


  }
  removeCoinsPercentage(i) {
    this.coinsPercentage.splice(i, 1)
  }
  // Save Abou Us Functionality
  descriptionPlan: any
  submit() {

    if(!this.imageUrl && !this.formNews.image){
      return this.service.toasterErr('Please select News Image')
    }
    if(!this.formNews.description){
      return this.service.toasterErr('News Description cannot be empty')
    }
    let tags = []
    for (let item of this.tags) {
      if (item.tag && !item.newsTagId) {

        tags.push(item.tag)
      }
    }
    var apiReq = this.formNews
    apiReq['tag'] = []
    apiReq['image'] =  this.imageUrl || this.formNews.image
    apiReq['updatedTime'] = new Date().toISOString()
    delete apiReq.newsTag
    delete apiReq.category
    delete apiReq.count
    delete apiReq.isLike
    delete apiReq.newsId
    delete apiReq.newsLetterStaticId
    delete apiReq.tag
    delete apiReq.type
    var dom = document.createElement("DIV");
    dom.innerHTML = this.formNews.description;
    var plain_text = (dom.textContent || dom.innerText);
    plain_text = plain_text.trim()
    plain_text = plain_text.replace(/\n/g, ' ')
    plain_text = plain_text.replace(/\t/g, ' ')
    plain_text = plain_text.replace(/[^\x20-\x7E]/g, '')
    apiReq['descriptionPlanText'] = plain_text


    this.service.showSpinner();
    this.service.post('static/update-news?newsLetterStaticId=' + this.newsId + '&tag=' + tags.toString(), apiReq).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.router.navigate(['/news-list'])

        this.service.toasterSucc('News added Successfully')
      } else {
        this.service.toasterErr('News not added Successfully')
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

}
