import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-news-category-add',
  templateUrl: './news-category-add.component.html',
  styleUrls: ['./news-category-add.component.css']
})
export class NewsCategoryAddComponent implements OnInit {
  addFaqForm:FormGroup;
  pageNumber:number=1
  currTab: any;
  constructor(public route:Router,public service:ServiceService, public active:ActivatedRoute) 
  {
    this.active.queryParams.subscribe((params)=>{
      this.currTab=params.tab
    })
   }

  ngOnInit(): void {
    this.formValidation();
  }

  formValidation(){
    this.addFaqForm= new FormGroup({
      'title':new FormControl('', [Validators.required,Validators.pattern('')]),
    })
  }


  addFaqLanguage(){
    if(this.currTab=='English'){
      this.addFaq();
    }
  }

  
  addFaq(){
    let request = {
      'category':this.addFaqForm.value.title, 
    }
    
   this.service.post(`static/add-news-category`,request).subscribe((res:any)=>{
     if (res.status=200) {
       this.service.toasterSucc(res.message)
       this.route.navigate(['/news-category-list'])
     }
    },err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Something Went Wrong');
   }
    })

  }

  
}
