import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-wallet-address',
  templateUrl: './add-wallet-address.component.html',
  styleUrls: ['./add-wallet-address.component.css']
})
export class AddWalletAddressComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
