import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-order-book-update',
  templateUrl: './order-book-update.component.html',
  styleUrls: ['./order-book-update.component.css']
})
export class OrderBookUpdateComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  userList: any = []
  constructor(private router: Router, public commonService: ServiceService, private httpClient: HttpClient) { }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getUserList()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'apiKey': new FormControl('', [Validators.required]),
      'baseUrl': new FormControl('', [Validators.required]),
      'secretKey': new FormControl('', [Validators.required]),
      'liquidityStatus': new FormControl('', [Validators.required]),
    })
  }
  addSwatches() {
    // this.router.navigate(['/swatches/list-swatches'])
    let url = `order/admin/liquidity-platform-add-update`
    let data = {
      'apiKey': this.addSwatchesForm.value.apiKey,
      'baseUrl': this.addSwatchesForm.value.baseUrl,
      'secretKey': this.addSwatchesForm.value.secretKey,
      'liquidityStatus': this.addSwatchesForm.value.liquidityStatus,
      'allOrderUrl': "/api/v3/allOrders",
      'cancelOrderUrl': "/api/v3/order",
      'cancelRequestType': "PARAMS",
      'headerName': "X-MBX-APIKEY",
      'liquidityPlatformAction': "ENABLE",
      'liquidityPlatformId': 1,
      'newOrderUrl': "/api/v3/order",
      'newRequestType': null,
      'openOrdersUrl': null,
      'openRequestType': null,
      'orderIdKey': "orderId",
      'platformName': "BINANCE",
      'priceKey': "price",
      'quantityKey': "quantity",
      'sideKey': "side",
      'stopPriceKey': "stopPrice",
      'symbolKey': "symbol",
      'testOrderUrl': null,
      'testRequestType': null,
      'typeKey': "type"
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        // this.router.navigate(['/fee-management'])
        this.router.navigate(['/order-book-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },
    (err)=>{
      this.commonService.hideSpinner()
    }
    )
  }
  // Get List of User
  getUserList() {
    var url = `order/admin/get-liquidity-platform-list`;
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe((res: any) => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        this.userList = res['data'][0];
        this.addSwatchesForm.patchValue({
          apiKey: this.userList.apiKey,
          baseUrl: this.userList.baseUrl,
          secretKey: this.userList.secretKey,
          liquidityStatus: this.userList.liquidityStatus
        })
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.hideSpinner()
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
}
