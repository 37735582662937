import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-view-aip-details',
  templateUrl: './view-aip-details.component.html',
  styleUrls: ['./view-aip-details.component.css']
})
export class ViewAipDetailsComponent implements OnInit {

  contentId: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  planId: any
  planDetail : any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.planId = res.planId;
    })
    this.viewAIP();
  }
  viewAIP() {
    this.commonService.showSpinner()
    this.commonService.get("wallet/view-auto-invest-full-details-plan-id?planId=" + this.planId).subscribe((res: any) => {
      if (res.status = 200) {
        this.planDetail = res.data[0]
        this.editData = res.data;
        this.commonService.hideSpinner()

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }

}
