import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  userDetail: any = {};
  editForm: FormGroup;
  editImage: any;
  twoFAType : any
  qrCode: any
  secretKey: any
  googleAuth: any = "";
  ipAddress: string;


  constructor(public router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.myProfile();
  }

  myProfile() {
    this.editForm = new FormGroup({
      'name': new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'city': new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'country': new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'email': new FormControl(''),
      'phone':new FormControl(''),
      'twoFAType':new FormControl(''),
      'address': new FormControl('',Validators.required)
    })
    var url = 'account/my-account';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        this.editImage = this.userDetail.imageUrl;
        this.twoFAType = this.userDetail.twoFaType;
        this.editForm.patchValue({
          'name': this.userDetail.firstName,
          'email': this.userDetail.email,
          'phone': this.userDetail.phoneNo,
          'address': this.userDetail.address,
          'city': this.userDetail.city,
          'country': this.userDetail.country,
          'twoFAType' : this.userDetail.twoFaType
        })
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  spinner = false
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.spinner = true
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.editImage = res['data'];
        this.spinner = false
      }else{
        this.spinner = false
      }
    }, err => {
      this.spinner = false
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }


  updateProfile() {
   
    var apiReq = {
      "address": this.editForm.value.address,
      "city": this.editForm.value.city,
      "country": this.editForm.value.country,
      "firstName": this.editForm.value.name,
      "phoneNo": this.editForm.value.phone,
      "gender": this.editForm.value.gender,
      "email": this.editForm.value.email,
      "imageUrl": this.editImage ? this.editImage : this.userDetail.imageUrl
    }
    this.service.showSpinner();
    this.service.post('account/profile-update', apiReq).subscribe(res => {
      if (res['status'] == 200) {
        this.service.changeLoginSub('login')
        this.service.toasterSucc('Profile Updated Successfully');
        this.router.navigate(['/my-profile'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    }
  }

  // -----------------------------------------------------

  googleStatusCheck(e) {
    

    if(this.userDetail.twoFaType == 'GOOGLE' && e == 'EMAIL'){
      this.editForm.patchValue({
        twoFAType : 'GOOGLE'
      })
     return this.service.toasterErr("Disable Google Auth First");
    }
    else if (this.userDetail.twoFaType == 'EMAIL' && e == 'GOOGLE'){
      this.editForm.patchValue({
        twoFAType : 'EMAIL'
      })
      return this.service.toasterErr("Disable Email Auth First");
    }
    
    if (this.userDetail.twoFaType == "SKIP" ||
      this.userDetail.twoFaType == "NONE") {
        if(e== 'GOOGLE'){
          this.enableGoogleAuth()
        }
        if(e == 'EMAIL'){
          this.openEMAILAuthModal()
        }
     
    }
    else if (this.userDetail.twoFaType == 'GOOGLE') {
      this.openGoogleAuthModal()
    }
    else if (this.userDetail.twoFaType == 'EMAIL') {
      this.openEMAILAuthModal()
    }
   
  }

  // Enable Google Auth Functionality
  enableGoogleAuth() {
    if (!this.userDetail.email) {
      return this.service.toasterErr('Please update your profile.')
    } 
    else {
      if (
        this.userDetail.twoFaType == "SKIP" ||
        this.userDetail.twoFaType == "NONE" ||
        this.userDetail.twoFaType != "EMAIL"
      ) {
        this.service.showSpinner();
        this.service.get("account/google-auth").subscribe(
          (res) => {
            if (res["status"] == 200) {
              this.qrCode = res["data"]["qrCode"];
              this.secretKey = res["data"]["secretKey"];
              $("#googleAuth").modal({ backdrop: "static", keyboard: false });
              this.service.hideSpinner();
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
      } else {
        this.service.toasterErr("Disable GOOGLE Auth First");
      }
    }
  }

  //Disable Google Security
  openGoogleAuthModal() {

    this.googleAuth = "";
    $("#googleAuthDisable").modal({ backdrop: "static" });
  }
  disableTwoFA() {
    var apireq = {
      code: this.googleAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postApi("account/twoFa-disable", apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#googleAuthDisable").modal("hide");
          this.myProfile();
          this.googleAuth = "";
          this.service.toasterSucc("Google Auth Disabled Successfully");
        } else {
          this.service.toasterErr("Wrong Google Authentication Code");
          this.googleAuth = "";
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err["message"]);
      }
    );
  }
  verifyGoogleAuth() {
    var url = "account/verify-google-code";
    var apireq = {
      code: this.googleAuth,
      secretKey: this.secretKey,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.post(url, apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.qrCode = "";
          this.secretKey = "";
          this.googleAuth = "";
          this.myProfile();
          this.service.toasterSucc("Google Auth Enabled Successfully");
          $("#googleAuth").modal("hide");
        } else {
          this.service.toasterErr("Wrong Google Authentication Code");
          this.googleAuth = "";
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("Something Went Wrong");
      }
    );
  }



  // Enable Email Auth
  emailAuth : any
  openEMAILAuthModal() {
    if (!this.userDetail.email) {
      return this.service.toasterErr('Please update your profile.')
    } else {
      this.emailAuth = "";
      if (
        this.userDetail.twoFaType != "GOOGLE" 
      ) {
        this.service.showSpinner();
        this.service.get("account/resend-verify-otp").subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#emailAuth").modal({ backdrop: "static" });
              this.service.toasterSucc(res["message"]);
            } else {
              this.service.toasterErr(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.service.toasterErr("Email Address Not Verified");
            } else {
              this.service.toasterErr(err["message"]);
            }
          }
        );
      } else {
        this.service.toasterErr(
          "Disable Google Auth First" 
        );
      }
    }
  }

  getOtpEmailEnable(e) {
    this.emailAuth = e
    this.verifyEmailAuth()
  }
  getOtpEmailDisable(e) {
    this.emailAuth = e
    this.disableEmailAuth()
  }

  verifyEmailAuth() {
    var url = "account/verify-Email-Sms-code";
    // var url = 'account/get-user-login-details?userIdForLoginHistoy=' + this.profiledetails.userId ;

    // var url = 'account/verify-user?token=' + this.emailAuth
    var smsapireq = {
      emailOtp: this.emailAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postApi(url, smsapireq).subscribe(
      (res) => {
        // this.service.getCandyPixelForms(url).subscribe(res => {

        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.emailAuth = "";
          this.myProfile();
          this.service.toasterSucc("Email Auth Enable Successfully");
          $("#emailAuth").modal("hide");
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("Please wait for few minutes & reload the site.");
      }
    );
  }
  // end email authenticator

  // Disable email Auth Functionality
  disableEmailAuth() {
    var apireq = {
      emailOtp: this.emailAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service
      .postApi("account/email-sms-auth-disable", apireq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
            this.myProfile();
           
            $("#emailAuth").modal("hide");
          
            this.service.toasterSucc("Email Auth Disabled Successfully");
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.toasterErr(err["message"]);
        }
      );
  }

}
