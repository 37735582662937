import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-view-announcement',
  templateUrl: './view-announcement.component.html',
  styleUrls: ['./view-announcement.component.css']
})
export class ViewAnnouncementComponent implements OnInit {
  announcementId: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.announcementId = res.announcementId;
    })
    this.viewBlog()
  }

  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.get("static/view-announcement-new-subcategory-by-id?announcementSubId=" + this.announcementId).subscribe((res: any) => {
      if (res.status = 200) {
        this.editData = res.data;
        this.commonService.hideSpinner()

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }
}
