import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { element } from 'protractor';
@Component({
  selector: 'app-add-aip-percent',
  templateUrl: './add-aip-percent.component.html',
  styleUrls: ['./add-aip-percent.component.css']
})
export class AddAipPercentComponent implements OnInit {


  selectedItems = [];
  dropdownList = [
  ];

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'coinShortName',
    textField: 'coinShortName',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    enableCheckAll: false,
    limitSelection: 5
  };

  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  planId: any;
  productForm: FormGroup;

  aipPerList: any = [];
  flag: boolean = false
  max = 80
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public commonService: ServiceService, private fb: FormBuilder) {

    this.productForm = this.fb.group({
      name: '',
      quantities: this.fb.array([]),
    });
    this.activatedRoute.queryParams.subscribe((res) => {
      this.planId = res.planId
      this.flag = res.flag
      this.max = res.max
    })
  }

  ngOnInit() {

    this.aipPerPlan();
    this.addSwatchesFormValidation();

    this.getCoinList()

  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'coin': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)])
    })
  }
  set_max(me: number) {
    me = Number(me)
    if (me > this.max) {
      setTimeout(() => {
        this.addSwatchesForm.patchValue({
          percent: this.max
        })
      }, 100);
    }
  }

  coinList: any = []
  getCoinList() {
    let url = `wallet/coin/get-coin-list`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        // this.dropdownList = res.data
        let removeCoin = ["USDT"];
         let removeValue = res.data.filter((ele) => {
            return !removeCoin.includes(ele.coinShortName);
          });
          this.dropdownList = removeValue
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  aipPerPlan() {
    this.commonService.showSpinner();
    this.commonService.get("wallet/view-auto-invest-full-details").subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.aipPerList = res.data;
          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      },
      (error) => {
        this.commonService.hideSpinner();
      }
    );
  }
  onItemSelect(item: any) {
  }

  addCoin(coinShortName, event) {

    if (this.dropdownList.length > 4 || coinShortName['isSelected']) {
      return
    }
    var len = this.dropdownList.length;
    coinShortName['isSelected'] = true
    this.dropdownList.push(coinShortName)
  }
  removeCoin(i, coinShortName) {
    this.dropdownList.splice(i, 1)
    coinShortName['isSelected'] = false

  }
  totalPercentage: number = 0
  getPercentage(e, index) {

    this.totalPercentage = 0

    for (let i = 0; i < this.selectedItems.length; i++) {
      this.totalPercentage = (this.selectedItems[i]['coin_pecentage'] ? this.selectedItems[i]['coin_pecentage'] : 0) + this.totalPercentage
    }
    if (this.totalPercentage > 100) {
      setTimeout(() => {
        this.selectedItems[index]['coin_pecentage'] = 100 - (this.totalPercentage - e)
        this.commonService.toasterErr("Can not add more than 100 percentage")
      }, 100);
      return
    }
    for (let i = 0; i < this.selectedItems.length; i++) {
      if (i == index) {
        continue
      }
      else {
        if (this.selectedItems[i]['isEdited']) {

        }
        else {
          this.selectedItems[i]['coin_pecentage'] = 0
        }
      }
    }
  }


  coinNameShort = []
  coinNamePercentage = []


  addSwatches() {
    if (!this.selectedItems.length) {
      return false
    }
    for (let item of this.dropdownList) {
      this.coinNameShort.push(item.coinShortName)
      this.coinNamePercentage.push(item.coin_pecentage)
    }
    let coin = []
    let coinPerce = []
    this.selectedItems.forEach(element => {
      coin.push(element.coinShortName)
      coinPerce.push(element.coin_pecentage)
    });
    let url = `wallet/CoinPlan?planId=${this.planId}`
    let data = {
      'coinName': coin,
      'percentage': coinPerce,
      "role": "ADMIN"
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.hideSpinner();
        this.commonService.toasterSucc(res.message);
        this.navigateBack()
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  navigateBack() {
    if (this.flag) {
      this.router.navigate(['/edit-aip-details'], { queryParams: { planId: this.planId } })

    }
    else {
      this.router.navigate(['/view-aip-details'], { queryParams: { planId: this.planId } })

    }
  }


  quantities(): FormArray {
    return this.productForm.get("quantities") as FormArray
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      qty: '',
      price: '',
    })
  }

  addQuantity() {
    this.quantities().push(this.newQuantity());
  }

  removeQuantity(i: number) {
    this.quantities().removeAt(i);
  }

  onSubmit() {

  }
}
