import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-settlement-management',
  templateUrl: './settlement-management.component.html',
  styleUrls: ['./settlement-management.component.css']
})
export class SettlementManagementComponent implements OnInit {
  minAge: Date;
  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: '', fromdate: '' }
  searchByEmail: any;
  coinSelected: any = '';

  walletWithdrawList: any = []
  fixedWithdrawList: any = []
  swapWithdrawList: any = []
  itemsPerPage = 20
  currentPage = 1
  totalItems: any

  currTab: any = 'WALLET';

  totalAmount: any = 0;
  totalWithdrawRequest: any = 0;

  constructor(public service: ServiceService, private datePipe: DatePipe, private router: Router) { }

  ngOnInit() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())
    this.getCoinList()
    this.getWalletList()
  }

  // from/to date validation
  fromdate() {
    this.fromDate = new Date(this.calender.fromdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }

  // select tab
  selectTab(tab) {
    this.currentPage = 1
    this.totalItems = 0
    this.currTab = tab
    this.searchByEmail = ''
    this.fromDate = ''
    this.twoDate = ''
    this.calender = { todate: '', fromdate: '' }
    switch (this.currTab) {
      case 'WALLET':
        this.getWalletList();
        this.walletWithdrawList = []
        break;
      case 'FIXED':
        this.fixedWithdrawList = []
        this.getFixedList();
        break;
      case 'SWAP':
        this.swapWithdrawList = []
        this.getSwapList();
        break
    }
  }


  settleAll() { }

  // get active logs of customer
  getWalletList() {
    // 'wallet/get-settlement-management-list-of-withdraw?page=0&pageSize=10'
    let apiReqUrl = `${'wallet/wallet/get-settlement-management-list-of-withdraw?page=' + (this.currentPage - 1) + '&pageSize=' + this.itemsPerPage +
      (this.searchByEmail ? ('&email=' + this.searchByEmail) : '') + (this.coinSelected ? ('&assetType=' + this.coinSelected) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    this.service.showSpinner();
    this.service.get(apiReqUrl).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.walletWithdrawList = res['data'].list.map(ele => ({ ...ele, isSelected: false }))
        // this.colorListArray = res.result.map(ele => ({ ...ele, isSelected: false }))  // add isSelected key to show check-right

        this.totalItems = res['data'].totalCount;
        this.totalAmount = res['data'].totalWithdrawAmount;
        this.totalWithdrawRequest = res['data'].totalWithdrawRequest;
        // this.service.toasterSucc(res['message'])
      } else {
        this.walletWithdrawList = []
        this.totalItems = 0
        this.totalAmount = 0
        this.totalWithdrawRequest = 0
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
    })
  }
  checkWithdrawalItem(item) {
    if (item) {
      item.isSelected = false;
    } else {
      item.isSelected = true;
    }
  }
  // get active logs of staff
  getFixedList() {
    let apiReqUrl = `${'wallet/get-fixed-deposite-list?page=' + (this.currentPage - 1) + '&pageSize=' + this.itemsPerPage +
      (this.searchByEmail ? ('&search=' + this.searchByEmail) : '') + (this.coinSelected ? ('&coinName=' + this.coinSelected) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`

    this.service.showSpinner();
    this.service.get(apiReqUrl).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.fixedWithdrawList = res['data'].list
        this.totalItems = res['data'].totalCount
        this.totalAmount = res['data'].totalWithdrawAmount;
        this.totalWithdrawRequest = res['data'].totalWithdrawRequest;
      } else {
        this.fixedWithdrawList = []
        this.totalItems = 0
        this.totalAmount = 0
        this.totalWithdrawRequest = 0
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
    })
  }

  // get active logs of admin
  getSwapList() {
    let apiReqUrl = `${'wallet/get-swap-awaiting-list?page=' + (this.currentPage - 1) + '&pageSize=' + this.itemsPerPage +
      (this.searchByEmail ? ('&search=' + this.searchByEmail) : '') + (this.coinSelected ? ('&swapType=' + this.coinSelected) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    this.service.showSpinner();
    this.service.get(apiReqUrl).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.swapWithdrawList = res['data'].list
        this.totalItems = res['data'].totalCount
        this.totalAmount = res['data'].totalWithdrawAmount;
        this.totalWithdrawRequest = res['data'].totalWithdrawRequest;
      } else {
        this.swapWithdrawList = []
        this.totalItems = 0
        this.totalAmount = 0
        this.totalWithdrawRequest = 0
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
    })
  }

  // common search for active logs of customer/staff/agent
  search() {
    if (this.searchByEmail || this.fromDate || this.twoDate || this.coinSelected) {
      switch (this.currTab) {
        case 'WALLET':
          this.getWalletList();
          break;
        case 'FIXED':
          this.getFixedList();
          break;
        case 'SWAP':
          this.getSwapList();
          break
      }
    } else {
    }
  }
  // common reset for active logs of customer/staff/agent
  reset() {
    if (this.searchByEmail || this.fromDate || this.twoDate || this.coinSelected) {
      this.searchByEmail = ''
      this.fromDate = ''
      this.twoDate = ''
      this.calender = { todate: '', fromdate: '' }
      this.coinSelected = ''
      switch (this.currTab) {
        case 'WALLET':
          this.getWalletList();
          break;
        case 'FIXED':
          this.getFixedList();
          break;
        case 'SWAP':
          this.getSwapList();
          break
      }
    }
  }

  // common pagination for active logs of customer/staff/agent
  pagination(page) {
    this.currentPage = page
    switch (this.currTab) {
      case 'WALLET':
        this.getWalletList();
        break;
      case 'FIXED':
        this.getFixedList();
        break;
      case 'SWAP':
        this.getSwapList();
        break
    }
  }

  // common export as excel for active logs of customer/staff/agent
  exportAsXLXS() {
    let dataArr = [];
    switch (this.currTab) {
      case 'WALLET':
        this.walletWithdrawList.forEach((element) => {
          dataArr.push({
            'User Id': element.userId ? element.userId : '--',
            'Email': element.email ? element.email : '--',
            'Date & Time': element.createTime ? this.datePipe.transform(element.createTime) : '--',
            'IP Address': element.ipAddress ? element.ipAddress : '--',
            'Browser Agent': element.userAgent ? element.userAgent : '--'
          })
        })
        break;
      case 'FIXED':
        this.fixedWithdrawList.forEach((element) => {
          dataArr.push({
            'User Id': element.userId ? element.userId : '--',
            'Staff Email': element.email ? element.email : '--',
            'Date & Time': element.createTime ? this.datePipe.transform(element.createTime) : '--',
            'IP Address': element.ipAddress ? element.ipAddress : '--',
            'Browser Agent': element.userAgent ? element.userAgent : '--'
          })
        })
        break;
      case 'SWAP':
        this.swapWithdrawList.forEach((element) => {
          dataArr.push({
            'User Id': element.userId ? element.userId : '--',
            'Email': element.email ? element.email : '--',

            'Date & Time': element.createTime ? this.datePipe.transform(element.createTime) : '--',
            'IP Address': element.ipAddress ? element.ipAddress : '--',
            'Browser Agent': element.userAgent ? element.userAgent : '--'
          })
        })
        break;
    }
    this.service.exportAsExcelFile(dataArr, 'Activity Logs ' + this.currTab.charAt(0).toUpperCase() + this.currTab.slice(1))
  }

  viewSettlement(managementShow, id) {
    this.router.navigate(['/view-settlement'], { queryParams: { managementShow: managementShow, id: id } })
  }

  coinList: any = []
  getCoinList() {
    this.service.showSpinner();
    this.service.get('wallet/coin/get-coin-list').subscribe((res) => {
      if (res['status'] == 200) {
        this.coinList = res['data']
        let removeCoin = ['INR']
        this.coinList = this.coinList.filter((ele) => {
          return !removeCoin.includes(ele.coinShortName)
        })

        this.service.hideSpinner();
      }

      else {
        this.service.hideSpinner();
      }
    })
  }


  validateForm() {
    if (
      this.searchByEmail || this.fromDate || this.twoDate || this.coinSelected
    ) {

      return false
    }
    else {
      return true
    }

  }



}