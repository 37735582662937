import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { isNgTemplate } from '@angular/compiler';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  userDetail: any;
  profile = ''
  pageNumber: number = 1;
  userID: any;
  itemsPerPage: any = 10
  userId: any = [];
  currTab: any = 'general';
  currTabb: any = 'BTC';
  transferForm: FormGroup;
  loginDetails: any = [];
  email: any;
  tradingForm: FormGroup;
  tradingList: any = [];
  tradingListlength: any;
  viewTrading: boolean = false;
  viewTradingg: boolean = false;
  viewEth: boolean = false;
  viewXrp: boolean = false;
  viewLtc: boolean = false;
  walletDetailsList: any = [];
  walletDetailsListLength: any;
  coinList: any = [];
  coinlist: any;
  coinListArr: any = [];
  tradingCurrency: any = ''
  tradingStatus: any = ''
  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: this.twoDate, fromdate: this.fromDate }
  minAge: Date;

  totalItems: number;
  tradestatus: any;
  y: any;
  copyToClipboard(item) {


    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.service.toasterSucc('Copied to clipboard')
  }
  constructor(private router: Router, public service: ServiceService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.checkTransferFormValidations();
    this.y = this.tradingCurrency
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
    this.fromdate()
    this.tradingForm = new FormGroup({
      tradingfromDate: new FormControl(''),
      tradingtoDate: new FormControl(''),
      tradingStatus: new FormControl(''),
    })

    let obj = this.route.queryParams.subscribe(params => {

      this.userId = (params['userId']); // (+) converts string 'id' to a number
      this.email = (params['email']);

      localStorage.setItem('userId', this.userId)
    });
    this.viewDetail();
    this.getCoinWalletList();
    this.getTradingHistory()
  }

  fromdate() {
    this.fromDate = new Date(this.calender.fromdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }

  // form validatioons 
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'search': new FormControl(''),
    })
  }

  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab == 'buySell') {
      this.instantBuySell();
    }
  }
  selectTabb(coin) {
    this.walletDetailss(coin.target.value)
  }

  performAction() { }
  walletDetails() {
    this.service.showSpinner();
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?coinName=' + (this.currTabb) + '&page=' + (0) + '&pageSize=' + (10) + '&fkUserId=' + (this.userId)).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.walletDetailsList = res.data.resultlist;
        this.walletDetailsListLength = res.data.totalCount;
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }
  instantBuySellDetail: any
  instantBuySellDetailTotal: any
  instantBuySell() {
    this.service.showSpinner();
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?deposit=INSTANT&page=' + (this.pageNumber - 1) + '&pageSize=' + (10) + '&fkUserId=' + (this.userId) + '&fromDate' + this.transferForm.value.fromDate + '&toDate=' + this.transferForm.value.toDate + '&userName=' + this.transferForm.value.search).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.instantBuySellDetail = res.data.resultlist;
        this.instantBuySellDetailTotal = res.data.totalCount;
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  paginationBuySell(page) {
    this.pageNumber = page;
    this.instantBuySell();
  }

  getProfile() {
    this.service.showSpinner();
    this.service.get('account/get-user?email=' + (this.email)).subscribe((res) => {
      this.service.hideSpinner();
      this.loginDetails = res['data']
    })
  }
  viewCoinDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewTradingg = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  viewCoinBTCDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewEth = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  viewXrpCoinDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewXrp = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  viewLtcCoinDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewLtc = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  nomineeListArray: any = []
  getNominee() {
    this.service.showSpinner();
    this.service.get(`account/get-details-by-userId`).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.nomineeListArray = res["data"];
          this.service.hideSpinner();

        } else {
          this.service.hideSpinner();
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err['message']);
      }
    );
  }
  viewNominee(subTeamId) {
    this.router.navigate(['/nominee-view'], { queryParams: { subTeamId: subTeamId } })
  }
  getCoinWalletList() {
    this.service.showSpinner();
    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
      if (res.status == 200) {
        this.coinlist = res.data;

        this.coinlist.forEach(element => {
          this.coinListArr.push(element.coinShortName);
          let removeCoin = ["BCH", "OMG", "XLM", "DASH", "LTC", "INR"];
          this.coinList = this.coinList.filter((ele) => {
            return !removeCoin.includes(ele.coinShortName);
          });
        });
        this.service.hideSpinner();

      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  getCoinDetailsByuserId(transactionId) {
    this.service.get('wallet/admin/transaction-history/get-transaction-details?txnId=' + (transactionId)).subscribe((res: any) => {
      if (res.status == 200) {
        this.coinList = res.data;
      }
    })
  }

  viewTradingDetails(userId, transactionId) {
    this.service.showSpinner();
    this.getTradingHistoryByuserId(userId, transactionId);
    this.viewTrading = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000)
  }
  getTradingHistoryByuserId(userId, transactionId) {
    let data = {
      "userId": this.userId,
      "transactionId": transactionId
    }
    this.service.post('order-service/get-trade-history', data).subscribe((res: any) => {
      if (res.status == 200) {
        this.tradingList = res.data[0];
      }
    })
  }
  backk() {
    this.viewTrading = false;
    this.viewDetail();
    this.getProfile();
    this.getTradingHistory();
    this.walletDetails();
  }
  backkk() {
    this.viewTradingg = false;
    this.viewDetail();
    this.getProfile();
    this.getTradingHistory();
    this.walletDetails();
  }
  backfromEth() {
    this.viewEth = false;
    this.viewDetail();
    this.getProfile();
    this.getTradingHistory();
    this.walletDetails();
  }

  backfromXrp() {
    this.viewXrp = false;
    this.viewDetail();
    this.getProfile();
    this.getTradingHistory();
    this.walletDetails();
  }

  backfromLtc() {
    this.viewLtc = false;
    this.viewDetail();
    this.getProfile();
    this.getTradingHistory();
    this.walletDetails();
  }
  pagination(page) {
    this.pageNumber = page;
  }
  viewDetail() {
    var url = 'account/admin/user-management/user-details?userId=' + this.userId;
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.getProfile();
        this.getTradingHistory();
        this.walletDetails();
        this.userDetail = res['data']
        this.profile = this.userDetail.imageUrl
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  back() {
    this.router.navigate(['user-management/'])
  }


  walletDetailss(data) {
    this.service.showSpinner();
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?coinName=' + (data) + '&page=' + (0) + '&pageSize=' + (10) + '&fkUserId=' + (this.userId)).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.walletDetailsList = res.data.resultlist;
        this.walletDetailsListLength = res.data.totalCount;
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }


  getTradingHistory() {
    let data = {
      "userId": this.userId,
      "baseCoin": this.y,
      "status": this.tradestatus
    }
    this.service.post("order-service/get-trade-history", {}).subscribe((res: any) => {

      if (res.status == 200) {
        this.tradingList = res.data;
        this.tradingListlength = res.data.length;
      }
    })
  }

  reverseArr() {
    let arr = [1, 2, 3, 4, 5, 6, 7];
    let newArr = [];
    let i;
    for (arr.length - 1; i >= 0; i--) {
      newArr.push(arr[i])
    }

  }

  searchTrading() {
    this.service.showSpinner();
    let searchAndFilterDto = {

      "baseCoin": this.tradingCurrency ? this.tradingCurrency : null,
      "fromDate": this.fromDate ? this.fromDate : null,
      "side": this.tradingStatus ? this.tradingStatus : null,
      "toDate": this.twoDate ? this.twoDate : null,
      "userId": this.userId
    }
    this.service.post("order-service/get-trade-history", searchAndFilterDto).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.tradingList = res.data;
        this.tradingListlength = res.data.length;
        this.service.toasterSucc('Success')


      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })



  }

  reset() {
    this.getTradingHistory();
    this.tradingList = [];
    this.tradingListlength = 0
    this.pageNumber = 1;

    if (this.fromDate || this.twoDate || this.tradingStatus || this.tradingCurrency) {

      this.fromDate = ''
      this.twoDate = ''
      this.calender = { todate: '', fromdate: '' }
      this.tradingCurrency = ''
      this.tradingStatus = ''
    }
    else {

    }
  }

  reset1() {
    this.transferForm.reset({
      fromDate: "",
      toDate: "",
      search: "",
    });
    this.instantBuySell();
  }


  exportAsXLSX() {
    let dataArr = [];
    this.tradingList.forEach((element, ind) => {

      dataArr.push({
        "S No": ind + 1,
        "Transaction ID": element.transactionId ? element.transactionId : '--',
        "Transaction Type": element.orderSide ? element.orderSide : '--',
        "Amount": element.quantity ? element.quantity : '--',
        "Base Coin": element.baseCoin ? element.baseCoin : '--',
        "Executable Coin": element.exeCoin ? element.exeCoin : '--',
        "Date and Time": element.lastExecutionTime ? element.lastExecutionTime.slice(0, 10) : '--',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Trading list');
  }
  validateForm() {
    if (
      this.fromDate || this.twoDate || this.tradingStatus || this.tradingCurrency) {
      return false
    }
    else {
      return true
    }

  }

  getTrxHashID(hash, id) {
    if (hash == null || hash == '') {
      return id
    } else {
      return hash
    }
  }
}
