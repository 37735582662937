import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
declare var $: any
@Component({
  selector: 'app-funds-list',
  templateUrl: './funds-list.component.html',
  styleUrls: ['./funds-list.component.css']
})
export class FundsListComponent implements OnInit {
  selected: string = "btc";
  coinlist: any = [];
  coinListArr: any = [];
  transferForm: FormGroup;
  setInrFee: FormGroup;
  tdsForm: FormGroup;
  loopData: any = [];
  txndata: any = []
  lengthTotal: any;
  minDate: any;
  itemsPerPage = 10;
  currentPage: number = 1;
  currentPageWithdraw: number = 1;
  totalItems: any
  flip: string = "List";
  addAmountForm: FormGroup
  today: any = new Date().toISOString().slice(0, 16)
  currTab: any = 'HOT';
  withdrawForm: FormGroup;
  feesSetForm: FormGroup;
  InrDepositInrAmount: FormGroup
  constructor(
    private router: Router,
    public service: ServiceService
  ) { }

  ngOnInit() {
    this.addAmountFormValidation()
    this.tdsValidation();
    this.checkTransferFormValidations();
    this.widthdrawFormValidation()
    this.feeSet();
    this.amountMin();
    this.getTds()
    this.getAllTransactionHistory();
    this.adminFund();
    this.getFundCountDetails();
    this.setInrFeeForm();
  }

  addAmountFormValidation() {
    this.addAmountForm = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)])
    })
  }
  tdsValidation() {
    this.tdsForm = new FormGroup({
      percent: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)])
    })
  }

  widthdrawFormValidation() {
    this.withdrawForm = new FormGroup({
      status: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),

    })
  }
  feeSet() {
    this.feesSetForm = new FormGroup({
      depositFee: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      withdrawFee: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)])
    })
  }
  amountMin() {
    this.InrDepositInrAmount = new FormGroup({
      depositAmountMin: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      withdrawAmountMin: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      withdrawAmountMax: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      depositAmountMax: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)])
    })
  }
  setInrFeeForm() {
    this.setInrFee = new FormGroup({
      coinShortName: new FormControl('INR'),
      fiatFee: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      fiatMaxAmount: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      fiatMinAmount: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)])
    })
  }



  copyToClipboard(item) {

    this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  pagination(page) {

    this.currentPage = page
    if (this.flip == "searchCoin") {
      this.forSerachValue();
    } else if (this.flip == "searchCoinn") {
    }
    else {
      this.getAllTransactionHistory()
    }

  }
  paginationWithdraw(page) {
    this.currentPageWithdraw = page
    this.getWithdralHistoryInr()
  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'type': new FormControl(''),
      'userEmail': new FormControl(''),
    })
  }
  forSerachValue() {
    let type = this.transferForm.value.type;
    let userEmail = this.transferForm.value.userEmail
    let url = `wallet/admin/Deposi-Inr-List-admin?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.type ? "&fiatStatus=" + type : ""}${this.transferForm.value.userEmail ? "&utrNo=" + userEmail : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.loopData = res.data.list;
          this.lengthTotal = res['data'].count
        }
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  searchByWithdrawList() {
    let status = this.withdrawForm.value.status
    let url = `wallet/admin/withdraw-Inr-List-admin?page=${this.currentPageWithdraw - 1}&pageSize=${this.itemsPerPage}${this.withdrawForm.value.status ? "&fiatStatus=" + status : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.widtralDataInr = res.data.list;
          this.lengthData = res['data'].count
        }
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  resetWithdraw() {
    if (this.withdrawForm.value.status) {
      this.withdrawForm.reset({
        status: ""
      })
      this.getWithdralHistoryInr()
    }
  }
  reset() {
    if (

      this.transferForm.value.type ||
      this.transferForm.value.userEmail

    ) {
      this.transferForm.reset({
        type: "",
        userEmail: "",
      });
      this.getAllTransactionHistory();
    }
  }


  getAllTransactionHistory() {
    this.service.showSpinner()
    this.service.get(`wallet/admin/Deposi-Inr-List-admin?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}`).subscribe((res: any) => {
      this.service.hideSpinner()
      this.loopData = res.data.list;
      this.lengthTotal = res['data'].count

    }, err => {
      this.service.hideSpinner();
      if (err.status >= 400) {
        this.service.toasterErr(err.message)
      }
    })

  }
  widtralDataInr: any = []
  lengthData: any
  getWithdralHistoryInr() {
    this.service.showSpinner()
    this.service.get(`wallet/admin/withdraw-Inr-List-admin?page=${this.currentPageWithdraw - 1}&pageSize=${this.itemsPerPage}`).subscribe((res: any) => {
      this.service.hideSpinner()
      this.widtralDataInr = res.data.list;
      this.lengthData = res['data'].count

    }, err => {
      this.service.hideSpinner();
      if (err.status >= 400) {
        this.service.toasterErr(err.message)
      }
    })

  }
  changeTicketStatus(fiatStatus, utrNo) {
    let data = {}
    this.service.post(`wallet/admin/Deposit-Inr-Status?fiatStatus=${fiatStatus}&utrNo=${utrNo}`, data).subscribe((res) => {
      if (res['status'] == 200) {
        this.getAllTransactionHistory()
      }
    })
  }
  changeWithdrawStatus(event, userId1, amount, fiatStatus, fiatId) {
    let data = {}
    this.service.post(`wallet/admin/withdraw-Inr-Status?fiatStatus=${event}&userId1=${userId1}&amount=${amount}&id=${fiatId}`, data).subscribe((res) => {
      if (res['status'] == 200) {
        this.getAllTransactionHistory()
      }
    })
  }
  addAmountvalue() {
    let url = `wallet/admin/add-Amount`
    let data = {
      amount: this.addAmountForm.value.amount
    }
    this.service.post(url, data).subscribe((res) => {
      if (res['status'] == 200) {
        $('#transferModal').modal({ backdrop: 'static' });
        this.getAllTransactionHistory()
      }
    })
  }
  addTds() {
    let url = `wallet/wallet/add-tds-percentage?tdsId=1`
    let data = {
      tdsPercentage: this.tdsForm.value.percent
    }
    this.service.post(url, data).subscribe((res) => {
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
        $('#tdsModal').modal({ backdrop: 'static' });
        this.getTds();
      }
    }, err => {
      this.service.toasterErr(err.error.message)
    })
  }
  tdsData: any
  getTds() {
    let url = `wallet/wallet/get-tds-percentage`
    this.service.get(url).subscribe((res) => {
      if (res['status'] == 200) {
        this.tdsData = res['data'][0].tdsPercentage
        // this.service.toasterSucc(res['message'])
      }
    }, err => {
      this.service.toasterErr(err.error.message)
    })
  }
  setFeesValue() {
    let url = `wallet/admin/update-deposit-withdraw-fee?deposite=${this.feesSetForm.value.depositFee}&withdraw=${this.feesSetForm.value.withdrawFee}`
    this.service.post(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        $('#setFeesModal').modal({ backdrop: 'static' });
        this.getAllTransactionHistory()
        this.adminFund()
      }
    })
  }
  setMinimumAmountValue() {
    let url = `wallet/admin/update-minimum-deposit-withdraw-fee?minimumdeposite=${this.InrDepositInrAmount.value.depositAmountMin}&minimumwithdraw=${this.InrDepositInrAmount.value.withdrawAmountMin}&maxWithdraw=${this.InrDepositInrAmount.value.withdrawAmountMax}&maxDeposit=${this.InrDepositInrAmount.value.depositAmountMax}`

    this.service.post(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        $('#setMinimumAmount').modal({ backdrop: 'static' });
        this.getAllTransactionHistory()
        this.adminFund()
      }
    })

  }
  selectTab(tab) {
    this.currTab = tab;
    if (tab == 'HOT') {
      this.coinListArr = [];
      this.getAllTransactionHistory()
    } else {
      this.getWithdralHistoryInr()
    }
  }
  fundDataArray: any = []
  adminFund() {
    let url = `wallet/admin/admin-Fund-Amount`
    this.service.showSpinner()
    this.service.get(url).subscribe((res) => {

      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.fundDataArray = res['data'][0]
        this.feesSetForm.patchValue({
          depositFee: this.fundDataArray.depositFee,
          withdrawFee: this.fundDataArray.withdrawFee
        })
        this.InrDepositInrAmount.patchValue({
          depositAmountMin: this.fundDataArray.minimumdeposite,
          withdrawAmountMin: this.fundDataArray.minimunwithdraw,
          withdrawAmountMax: this.fundDataArray.maxWithdraw,
          depositAmountMax: this.fundDataArray.maxDeposit,
        })
        this.setInrFee.patchValue({
          coinShortName: 'INR',
          fiatFee: this.fundDataArray.instantBuySellFee,
          fiatMaxAmount: this.fundDataArray.instantBuySellFeeMaxAmount,
          fiatMinAmount: this.fundDataArray.instantBuySellMinAmount
        })
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    })
  }

  viewBody(bank, userId, utrNo, fiatId, fiatStatus, amount) {
    this.router.navigate(['/fund-view'], { queryParams: { bankName: bank, userId: userId, utrNo: utrNo, fiatId: fiatId, fiatStatus: fiatStatus, amount: amount } });

  }
  viewWithdraw(bank, userId, utrNo, fiatId, fiatStatus, amount) {
    this.router.navigate(['/fund-view'], { queryParams: { bankName: bank, userId: userId, utrNo: utrNo, fiatId: fiatId, fiatStatus: fiatStatus, amount: amount } });

  }
  viewUpi(upiId, userId) {
    this.router.navigate(['/upi-view'], { queryParams: { upiId: upiId, userId: userId } });
  }
  fiatCryptoData: any = "CRYPTO"


  fiatCryptoList(datafiatCrypto, event) {
    this.fiatCryptoData = datafiatCrypto
  }
  validateForm() {
    if (
      this.transferForm.value.type ||
      this.transferForm.value.userEmail
    ) {
      return false
    }
    else {
      return true
    }

  }


  validateFormCold() {
    if (
      this.transferForm.value.type ||
      this.transferForm.value.userEmail

    ) {
      return false
    }
    else {
      return true
    }
  }

  validateFormColdnew() {
    if (
      this.withdrawForm.value.status

    ) {
      return false
    }
    else {
      return true
    }
  }

  foundCount: any
  getFundCountDetails() {
    this.service.get('wallet/wallet/fund-count-details').subscribe(res => {
      this.foundCount = res['data']
    })
  }

  setInrFeeFun() {
    let url = `wallet/admin/set-instant-buy-sell-min-max-(Amount-fee)-fiat?coinShortName=INR&fiatFee=${this.setInrFee.value.fiatFee}&fiatManAmount=${this.setInrFee.value.fiatMaxAmount}&fiatMinAmount=${this.setInrFee.value.fiatMinAmount}`
    this.service.showSpinner();
    this.service.post(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.adminFund();
        $('#InrModal').modal({ backdrop: 'static' });
        this.service.toasterSucc(res.message);

      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err.message)
    })
  }
  exportAsXLSXDeposite() {
    let type = this.transferForm.value.type;
    let userEmail = this.transferForm.value.userEmail
    let url = `wallet/admin/Deposi-Inr-List-admin?page=${this.currentPage - 1}&pageSize=${this.lengthTotal}${this.transferForm.value.type ? "&fiatStatus=" + type : ""}${this.transferForm.value.userEmail ? "&utrNo=" + userEmail : ""}`
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        let dataArr = [];
        res.data.list.forEach((element, ind) => {
          dataArr.push({
            "S no": ind + 1,
            "Name": element.name ? element.name : '--',
            "Email": element.email ? element.email : '--',
            "Actual Amount": element.amount ? element.amount : '--',
            "Paid Amount": element.feeDeductAmount ? element.feeDeductAmount : '--',
            "Utr No.": element.utrNo ? element.utrNo : '--',
            "Status.": element.fiatStatus ? element.fiatStatus : '--',
            "Date": element.createTime ? element.createTime.slice(0, 10) : '--',
          })
        })
        this.service.exportAsExcelFile(dataArr, 'Deposit list');
      }
    }, (err: any) => {
      this.service.toasterErr(err.error.message);
    }
    );
  }
  exportAsXLSXDWithdrawal() {
    this.service.get(`wallet/admin/withdraw-Inr-List-admin?page=${this.currentPageWithdraw - 1}&pageSize=${this.lengthData}`).subscribe((res: any) => {
      if (res.status == 200) {
        let dataArr = [];
        res.data.list.forEach((element, ind) => {
          dataArr.push({
            "S no": ind + 1,
            "Name": element.name ? element.name : '--',
            "Email": element.email ? element.email : '--',
            "Actual Amount": element.amount ? element.amount : '--',
            "Paid Amount": element.feeDeductAmount ? element.feeDeductAmount : '--',
            "Fiat Id": element.fiatId ? element.fiatId : '--',
            "User Id": element.userId ? element.userId : '--',
            "Status.": element.fiatStatus ? element.fiatStatus : '--',
            "Date": element.createTime ? element.createTime.slice(0, 10) : '--',
          })
        })
        this.service.exportAsExcelFile(dataArr, 'Withdrawal list');
      }
    }, (err: any) => {
      this.service.toasterErr(err.error.message);
    }
    );
  }


}

