import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $: any;
@Component({
  selector: 'app-trading-history',
  templateUrl: './trading-history.component.html',
  styleUrls: ['./trading-history.component.css']
})
export class TradingHistoryComponent implements OnInit {

  pageNumber: number = 1;
  status: string;
  kycList: any = [];
  kycData: any = {};
  userKycId: any;
  kycDetail: any;
  itemsPerPage: any = 10;
  transferForm: FormGroup;
  today: any = new Date().toISOString().split('T')[0]
  constructor(public service: ServiceService, public router: Router, public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(res => {
    })
  }

  ngOnInit() {
    this.checkTransferFormValidations()
    this.getAllCoinPairFunc();
    this.liquidity();
  }
  // form validatioons 
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'status': new FormControl(''),
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'pair': new FormControl('')
    })
  }

  reset() {
    this.transferForm.reset({
      fromDate: "",
      toDate: "",
      status: "",
      pair: ""
    });
    this.liquidity();
  }
  changePageNumber(page) {
    this.pageNumber = page;
  }
  disableSearchbtn() {
    if (this.transferForm.value.status || this.transferForm.value.fromDate || this.transferForm.value.toDate || this.transferForm.value.pair) {
      return false
    }
    else {
      return true
    }
  }

  baseCurrency: any = []
  baseCurrency111: any = []
  fullCoin: any = []
  exeCurrency: any = []
  arr: any = [];
  execCoin: any = "BTC";
  baseCoin: any = "USD";
  coinDetail: any = {};
  basecoins = "BTC";

  getAllCoinPairFunc() {
    this.arr = [];
    this.service.get("wallet/coin/get-symbol-list").subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.baseCurrency = res['data'];
          this.baseCoin = this.coinDetail.base;
          this.execCoin = this.coinDetail.exec;
          var ind = res.data.findIndex((x) => x.baseCoin == this.basecoins);
          if (ind > -1) {
            res.data[ind].execCoin.forEach((element) => {
              this.arr.push({
                base: this.basecoins,
                executable: element,
                lastPrice: "",
                hourChange: "",
                hourhigh: "",
                hourLow: "",
                hourVol: "",
              });
            });
          }
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  liquidityData: any = []
  currentPage: any = 0
  limit: any = 10
  liquidityCount: any
  liquidity() {
    let fromDate = Date.parse(this.transferForm.value.fromDate)
    let toDate = Date.parse(this.transferForm.value.toDate)
    this.service.showSpinner();
    let url = `order/admin/liquidity-list?instrument=${this.transferForm.value.pair ? this.transferForm.value.pair : 'BTC_USDT'}&page=${this.currentPage}&pageSize=${this.limit}${this.transferForm.value.status ? "&orderStatus=" + this.transferForm.value.status : ''}${fromDate ? "&fromDate=" + fromDate : ""}${toDate ? "&toDate=" + toDate : ""}`
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.liquidityData = res.data.List
          this.liquidityCount = res.data.count
        } else {
          this.service.toasterErr(res.message);
          this.liquidityData = []
        }
      },
      (err) => {
        this.liquidityData = []
        this.service.hideSpinner();
        this.service.toasterErr("No Data Found.");
      }
    );
  }

}
