import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent implements OnInit {
  calender  = {
    fromdate : '',
    todate : '',
    type : '',
    title : ''
  }
  currentPage = 1
  itemsPerPage = 10
  totalItems : any
  newsList : any = []
  filterArray : any = []
  constructor(public service : ServiceService,private router : Router) { }

  ngOnInit() {
    this.faqList()
    this.getnewsList()
  }
categoryList : any  =[]
  faqList(){
    let url =`static/get-all-news-details`
    this.service.showSpinner();
    this.service.get(url).subscribe((res:any)=>{
      if(res.status==200){
        this.service.hideSpinner()
        this.categoryList=res.data;
        this.totalItems = res.data.leng
      }

      this.service.hideSpinner()
      
    },(err)=>{
      if(err['status']==401){
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }
  getnewsList(){
    let url =`static/get-all-news-details`
    this.service.showSpinner();
    this.service.get(url).subscribe((res:any)=>{
      if(res.status==200){
        this.service.hideSpinner()
        let category =res.data;
        this.newsList = []
        for(let item of category){
          for(let items of item.newsSubCategory){
            let obj = items
            obj['category']=item.category
            this.newsList.push(obj)
            this.filterArray.push(obj)
          }
        }
        this.totalItems = this.newsList.length;
      }

      this.service.hideSpinner()
      
    },(err)=>{
      if(err['status']==401){
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }
  newsId : any
  deleteNewsId(id){
    this.newsId = id
    $('#deleteModal').modal('show')
  }
  editNews(id){
   this.router.navigate(['/news-edit'],{queryParams : {id}}) 
  }
  deleteUser(){
    let url="static/delete-news?newsLetterStaticId="+this.newsId;
    let data={}
    this.service.showSpinner();
    this.service.delete(url).subscribe((res:any)=>{
      if(res.status==200){
        this.service.hideSpinner();
        $('#deleteModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.getnewsList();
      }
    },(err)=>{
      if(err['status']==401){
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }
  search(){
    if(this.calender.title){
      this.filterArray = this.newsList.filter((res)=>{
        let name = String(res.title).toLowerCase()
        let subName = String(res.subTitle).toLowerCase()
        
        return subName.includes(String(this.calender.title).toLowerCase()) || name.includes(String(this.calender.title).toLowerCase()) 
      })
    }
    if(this.calender.type){
      this.filterArray = this.newsList.filter((res)=>{
        let name = String(res.category).toLowerCase()
       
        
        return name.includes(String(this.calender.type).toLowerCase()) 
      })
    }
  
    if(this.calender.fromdate){
      this.filterArray = this.filterArray.filter((res)=>{
        let createdAt = Date.parse(new Date(res.creationDate).toISOString().split('T')[0])
        return createdAt >= Date.parse(this.calender.fromdate)
      })
    }
    if(this.calender.todate){
      this.filterArray = this.filterArray.filter((res)=>{
        let createdAt = Date.parse(new Date(res.creationDate).toISOString().split('T')[0])
        return createdAt >= Date.parse(this.calender.fromdate) && createdAt <= Date.parse(this.calender.todate)
      })
    }
    this.totalItems = this.filterArray.length
    
  }
  viewNews(id){

  }
  reset(){
    this.calender.fromdate = ''
    this.calender.todate = ''
    this.calender.type = ''
    this.calender.title = ''

    this.filterArray = this.newsList
    this.totalItems = this.newsList.length
  }
  validateForm(){

  }
  pagination(e){
    this.currentPage = e
  }
}
