import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-trx-management',
  templateUrl: './trx-management.component.html',
  styleUrls: ['./trx-management.component.css']
})
export class TrxManagementComponent implements OnInit {

  constructor(public service: ServiceService) { }
  address: any
  ngOnInit(): void {
    this.getTotalCount()
  }
  getCount() {
    let url = `wallet/wallet/trx-count?count=${this.address}`
    this.service.showSpinner()
    this.service.postApi(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.toasterSucc('Successfully added new address');
        this.address = ''
        this.service.hideSpinner();
        this.getTotalCount();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    },
      (err) => {
        this.service.hideSpinner()
      })
  }
  totalCountdata: any
  remain: any
  datacount: any
  getTotalCount() {
    let url = `wallet/wallet/get-remaining-count`
    this.service.showSpinner()
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.totalCountdata = res.data
        let remain = (res.data.remainingCount)
        this.datacount = remain
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    },
      (err) => {
        this.service.hideSpinner()
      })
  }

  validateForm() {
    if (
      this.address
    ) {

      return false
    }
    else {
      return true
    }

  }





}
