import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-edit-newly-added-coin',
  templateUrl: './edit-newly-added-coin.component.html',
  styleUrls: ['./edit-newly-added-coin.component.css']
})
export class EditNewlyAddedCoinComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  coinName: any
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService, private httpClient: HttpClient, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.coinName = res.coinName;
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getCoinDetailsData()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'contractAddress': new FormControl('', [Validators.required, Validators.pattern(/^0x[a-fA-F0-9]{40}$/)]),
      'coinFullName': new FormControl('', [Validators.required]),
      'coinShortName': new FormControl('', [Validators.required]),
      // 'coinType': new FormControl('', [Validators.required]),
      'network': new FormControl('', [Validators.required]),
      'coinImage': new FormControl(''),
      // 'category': new FormControl(''),
      'visible': new FormControl(true, [Validators.required]),
      'cmc': new FormControl(true, [Validators.required]),
      'coinBasePrice': new FormControl('')
    })
  }
  addSwatches() {
    let url = `wallet/coin/coin-data-update?coinId=${this.coinIdData}&network=${this.addSwatchesForm.value.network}`
    let data = {
      'contractAddress': this.addSwatchesForm.value.contractAddress,
      'coinFullName': this.addSwatchesForm.value.coinFullName,
      'coinShortName': String(this.addSwatchesForm.value.coinShortName).toUpperCase(),
      'coinType': 'Crypto',
      'coinImage': this.imageUrl,
      'category': 'All',
      'coinBasePrice': this.addSwatchesForm.value.coinBasePrice,
      'visible': this.addSwatchesForm.value.visible,
      'priceCmc': this.addSwatchesForm.value.cmc,

    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.storageUpdate(String(this.addSwatchesForm.value.coinShortName).toUpperCase())
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/newly-added-coin-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  dataCoinFetch: any
  coinIdData: any
  network: any
  getCoinDetailsData() {
    var url = `wallet/coin/get-coin-details?coinName=${this.coinName}`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe(res => {

      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        this.dataCoinFetch = res['data']['coinData']
        this.network = res['data']['network'][0]
        this.coinIdData = res['data']['coinData']['coinId']
        this.addSwatchesForm.patchValue({
          contractAddress: this.dataCoinFetch.contractAddress,
          coinFullName: this.dataCoinFetch.coinFullName,
          coinShortName: this.dataCoinFetch.coinShortName,
          coinType: 'Crypto',
          network: this.network.networkSortName,
          coinBasePrice: this.dataCoinFetch.coinBasePrice,
          cmc : this.dataCoinFetch.priceCmc,
          visible : this.dataCoinFetch.visible,
        })
        this.imageUrl = this.dataCoinFetch.coinImage
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  dataArray: any
  checkWalletAddress($evant) {
    let url = `https://api.pancakeswap.info/api/v2/tokens/${this.addSwatchesForm.value.contractAddress}`
    this.httpClient.get(url).subscribe(res => {
      this.dataArray = res['data']
      this.addSwatchesForm.patchValue({
        coinShortName: this.dataArray.symbol,
        coinFullName: this.dataArray.name
      })
    })
  }
  storageUpdate(coin) {
    let data = {
      "coinName": coin,
      "storageType": 'HOT'
    }
    this.commonService.showSpinner();
    this.commonService.post('wallet/admin/hot-cold-storage/create-storage-wallet', data).subscribe((res) => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        this.commonService.toasterSucc(res['message'])
        this.newAddressGenerate(coin)
      }
      else {
        this.commonService.toasterErr(res['message']);
        this.commonService.hideSpinner();
      }
    }, (err) => {
      this.commonService.hideSpinner();
    })
  }

  newAddressGenerate(coin) {
    let data = {
      "accName": 1,
      "coinName": coin,
      "storageType": 'HOT'
    }
    this.commonService.showSpinner();
    this.commonService.post('wallet/admin/hot-cold-storage/get-new-storage-address', data).subscribe((res) => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        this.commonService.toasterSucc(res['message'])
      }
      else {
        this.commonService.toasterErr(res['message']);
        this.commonService.hideSpinner();
      }
    }, (err) => {
      this.commonService.hideSpinner();
    })
  }

}
