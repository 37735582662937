import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $: any;
@Component({
  selector: 'app-newly-added-coin-list',
  templateUrl: './newly-added-coin-list.component.html',
  styleUrls: ['./newly-added-coin-list.component.css']
})
export class NewlyAddedCoinListComponent implements OnInit {

  searchText: string = "";
  pageNumber: number = 1;
  status: string;
  kycList: any = [];
  kycData: any = {};
  userKycId: any;
  kycDetail: any;
  itemsPerPage: any = 10;
  flip: boolean = false;
  countryList: any = [];
  country: any;
  transferForm: FormGroup;
  val: any
  flag: any
  dashStatus: any
  today: any = new Date().toISOString().split('T')[0]
  constructor(public service: ServiceService, public router: Router, public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.flag = res.flag
      if (this.flag == 'pending') {
        this.dashStatus = 'PENDING'
      } else if (this.flag == 'accepted') {
        this.dashStatus = 'ACCEPTED'
      } else if (this.flag == 'rejected') {
        this.dashStatus = 'REJECTED'
      }
    })
  }

  ngOnInit() {
    this.checkTransferFormValidations()
    this.getListOfKyc();
    this.getCountryList();
  }


  // form validatioons 
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'kycStatus': new FormControl(''),
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'search': new FormControl(''),
      'country': new FormControl(''),

    })
    if (this.flag == 'pending') {
      this.transferForm.patchValue({
        kycStatus: 'PENDING'
      })
    } else if (this.flag == 'accepted') {
      this.transferForm.patchValue({
        kycStatus: 'ACCEPTED'
      })
    } else if (this.flag == 'rejected') {
      this.transferForm.patchValue({
        kycStatus: 'REJECTED'
      })
    }
  }

  reset() {
    this.transferForm.reset({
      fromDate: "",
      toDate: "",
      country: "",
      search: "",
      kycStatus: "",
    });
    this.dashStatus = ""
    this.getListOfKyc();
  }

  // get country list
  getCountryList() {
    this.service.get("account/get-country-list").subscribe((res) => {
      if (res["status"] == 200) {
        this.countryList = res["data"];
      }
    });
  }
  gotoview(id) {
    this.router.navigate(["/kyc-action/id"], {
      queryParams: { id: id },
    });
  }
  // getStatus Function
  getStatus(event) {
    this.pageNumber = 1;
    this.status = event.target.value;
    if (this.status != "") {
      this.getListOfKyc();
    } else {
      this.getListOfKyc();
    }
  }


  forSerachValue() {
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let search = this.transferForm.value.search;
    let kycStatus = this.transferForm.value.kycStatus
    let country = this.transferForm.value.country
    let url = `wallet/coin/get-coin-list-isVisible`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.kycList = res.data;
        }
        this.kycDetail = res['data'].totalCount
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  // getListOFKYC Function
  getListOfKyc() {
    let url = `wallet/coin/get-coin-list-isVisible`;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.kycDetail = res["data"].totalCount;
          this.kycList = res["data"];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  changePageNumber(page) {
    this.pageNumber = page;

    this.getListOfKyc();
  }

  // Get Particular KYC Detail
  getParticularKycDetail(userId, kycId) {
    this.userKycId = kycId;
    var url = "account/admin/kyc-management/get-kyc-details?userId=" + userId;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.kycData = res["data"]["document"];

          $("#view").modal("show");
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // Document Action  Functionality

  documentActionFunc(action, documentNumber, documentId) {
    var apiReq = {
      documentId: documentId,
      kycId: this.userKycId,
      reason: action == "ACCEPT" ? "Valid Document" : "Invalid Document",
      status: action,
    };
    this.service.showSpinner();
    this.service
      .post("account/admin/kyc-management/doc-status", apiReq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }

  disableSearchbtn() {

    if (this.transferForm.value.kycStatus || this.transferForm.value.fromDate || this.transferForm.value.toDate || this.transferForm.value.submit || this.transferForm.value.country || this.transferForm.value.search) {
      return false
    }
    else {
      return true
    }

  }

}
