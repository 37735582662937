import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-news-add',
  templateUrl: './news-add.component.html',
  styleUrls: ['./news-add.component.css']
})
export class NewsAddComponent implements OnInit {
  @ViewChild("myEditor", { static: false }) myEditor: any;
  tags = [{ tagName: '' }]
  coinsPercentage = [{ coinName: '', changeSign: '+', changePercentage: '' }]
  coinList: any
  imageUrl: any
  formNews: any = {
    title: '',
    subTitle: '',
    type: '',
    description: ''
  }
  constructor(public service: ServiceService, public route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.getCoinList();
    this.faqList()
  }
  getCoinList() {
    this.service.showSpinner();

    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinList = res.data

      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
      } else {
      }
    })
  }
  faqData: any = []
  faqList() {
    let url = `static/get-all-news-details`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        this.faqData = res.data;



      }

      this.service.hideSpinner()

    }, (err) => {
      if (err['status'] == 401) {
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      // this.service.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  addTag(tagname) {
    if (!tagname) {
      this.service.toasterErr('Please Enter Tag Name')
    }
    else {
      this.tags.push({ tagName: '' })
    }

  }
  removeTag(i) {
    this.tags.splice(i, 1)
  }
  addCoinsPercentage(coinName, percentage) {
    if (!coinName) {
      this.service.toasterErr('Please Select Coin Name')
      return
    }
    if (!percentage) {
      this.service.toasterErr('Please Enter Percentage')
      return
    }


    this.coinsPercentage.push({ coinName: '', changeSign: '+', changePercentage: '' })


  }
  removeCoinsPercentage(i) {
    this.coinsPercentage.splice(i, 1)
  }
  // Save Abou Us Functionality
  descriptionPlan: any
  submit() {

    if(!this.imageUrl){
      return this.service.toasterErr('Please select News Image')
    }
    if(!this.formNews.description){
      return this.service.toasterErr('News Description cannot be empty')
    }
    let tags = []
    for (let item of this.tags) {
      if (item.tagName) {

        tags.push(item.tagName)
      }
    }
    var apiReq = this.formNews
    apiReq['tag'] = []
    apiReq['image'] = this.imageUrl
    apiReq['creationDate'] = new Date().toISOString()
    var dom = document.createElement("DIV");
    dom.innerHTML=this.formNews.description;
    var plain_text=(dom.textContent || dom.innerText);
    plain_text = plain_text.trim()
    plain_text = plain_text.replace(/\n/g,' ')
    plain_text = plain_text.replace(/\t/g,' ')
    plain_text = plain_text.replace(/[^\x20-\x7E]/g,'')
    apiReq['descriptionPlanText'] = plain_text

   
    this.service.showSpinner();
    this.service.post('static/add-sub-category-for-news?newsId=' + this.formNews.type + '&tag=' + tags.toString(), apiReq).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        // this.getListCode();
        this.router.navigate(['/news-list'])

        this.service.toasterSucc('News added Successfully')
      } else {
        this.service.toasterErr('News not added Successfully')
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

}
