import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'app-referal-list',
  templateUrl: './referal-list.component.html',
  styleUrls: ['./referal-list.component.css']
})
export class ReferalListComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  announcementId: any;

  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';

  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getEmbellishmentList();
    // this.getRefList()
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getEmbellishmentList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  
  getEmbellishmentList() {
    let apiReqUrl: any = `account/admin/user-management/get-admin-referal`
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }else{
        this.commonService.hideSpinner();
      }

    })
  }

  pagination(event) {
    this.currentPage = event;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/referal-add'])
  }
  
  viewBody(announcementId) {
    this.router.navigate(['/view-announcement'], { queryParams: { announcementId: announcementId } })
  }

  
  editBody(announcementId) {
    this.router.navigate(['/edit-announcement'], { queryParams: { announcementId: announcementId } })
  }

  getRefList(){
    let url =`account/Referal-List`
    this.commonService.get(url).subscribe((res)=>{
      if(res['status'] == 200){

      }
    })
  }

  openModal(){

      $('#active').modal('show')
  }
  performActionActive() {
    $('#active').modal('hide')
   
    var url = `account/admin/user-management/admin-referal-statusupdate?referalStatus=ENDED`;
    this.commonService.post(url, '').subscribe((res)=>{
      this.getEmbellishmentList();
    })
  }
}
