import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $
@Component({
  selector: 'app-view-bank-detail-user',
  templateUrl: './view-bank-detail-user.component.html',
  styleUrls: ['./view-bank-detail-user.component.css']
})
export class ViewBankDetailUserComponent implements OnInit {

  userDetail: any;
  profile = ''
  itemsPerPage: any = 10
  userId: any = [];
  status: String
  image: any
  data:any
  constructor(private router: Router, public service: ServiceService, private route: ActivatedRoute) { 
  }

  ngOnInit() {

    let obj = this.route.queryParams.subscribe(params => {
      this.userId = (params['userId']);
      this.status = params['status']
      this.image = params['image']
      this.data = JSON.parse(params['dataValue'])
      console.log(this.data);
      
      localStorage.setItem('userId', this.userId)
    });
    this.viewDetail()

  }



  viewDetail() {
    var url = 'account/admin/user-management/user-details?userId=' + this.userId;
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data']
        this.profile = this.userDetail.imageUrl
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  back() {
    this.router.navigate(['/bank-management'])
  }
  openImgModal() {
    $('#img1').modal('show')
  }
}
