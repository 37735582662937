import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-add-aip-plan',
  templateUrl: './add-aip-plan.component.html',
  styleUrls: ['./add-aip-plan.component.css']
})
export class AddAipPlanComponent implements OnInit {
  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];

  planId: any

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public commonService: ServiceService, private httpClient: HttpClient) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.planId = res.planId
    })
   }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getCoinWalletList()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'aipPlan': new FormControl('', [Validators.required]),
      'description': new FormControl('', [Validators.required]),
    })
  }


coinList : any = []
  getCoinList(){
    let url = `wallet/coin/get-coin-list`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.coinList = res.data
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();
       
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }


  addSwatches() {

    let url = `wallet/autoInvestment`
    let data = {
      'planName': this.addSwatchesForm.value.aipPlan,
      'description': this.addSwatchesForm.value.description,
      'planId': this.planId
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();
        this.router.navigate(['/list-aip'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  coinlist: any = []

  getCoinWalletList() {
    this.commonService.showSpinner();
    this.commonService.get("wallet/coin/get-coin-list-isVisible").subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.coinlist = res.data;
          let removeCoin = ["BCH", "OMG", "XLM", "DASH", "LTC"];
          this.coinlist = this.coinlist.filter((ele) => {
            return !removeCoin.includes(ele.coinShortName);
          });
          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      },
      (error) => {
        this.commonService.hideSpinner();
      }
    );
  }
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

}
