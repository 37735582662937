import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customPipe'
})
export class CustomPipePipe implements PipeTransform {

  transform(value:any,limit:number){
    if(value!=null && value.length>limit){
      return value.substr(0,limit) + '...';
    }
    else if(value!=null){
      return value;
    }
  }

}
