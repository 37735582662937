import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceUnderScore'
})
export class ReplaceUnderScorePipe implements PipeTransform {

  transform(value) {
    if((value.length)!=null){
      let d=value.replaceAll('_'," ");
      return d;
    }
    else{
      return value;
    }
     
  }

}
