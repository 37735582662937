import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-instant-add-buy-sell',
  templateUrl: './instant-add-buy-sell.component.html',
  styleUrls: ['./instant-add-buy-sell.component.css']
})
export class InstantAddBuySellComponent implements OnInit {

  addSwatchesForm: FormGroup;
  addSwatchesBuyForm: FormGroup;
  imageUrl
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  coinShortName: any
  instantBuySellFee: any
  instantBuySellMaxAmt: any
  instantBuySellMinAmt: any
  buttonStatus: any
  tabStatus: string;
  constructor(private router: Router, public commonService: ServiceService, private httpClient: HttpClient, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.coinShortName = res.coinName;
      this.instantBuySellFee = res.instantBuySellFee;
      this.instantBuySellMaxAmt = res.instantBuySellMaxAmt;
      this.instantBuySellMinAmt = res.instantBuySellMinAmt;
      this.buttonStatus = res.buttonStatus;
      this.tabStatus = res.tabStatus;
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.addSwatchesFormBuyValidation();
    this.patchValue();
    this.patchBuyValue();
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'cryptoAmount': new FormControl('', [Validators.required]),
      'cryptoName': new FormControl('', [Validators.required]),
      'fiatAmount': new FormControl('', [Validators.required]),
      'fiatName': new FormControl('', [Validators.required]),
    })
  }
  addSwatchesFormBuyValidation() {
    this.addSwatchesBuyForm = new FormGroup({
      'cryptoAmount': new FormControl('', [Validators.required]),
      'cryptoName': new FormControl('', [Validators.required]),
      'fiatAmount': new FormControl('', [Validators.required]),
      'fiatName': new FormControl('', [Validators.required]),
    })
  }

  patchValue() {
    this.addSwatchesForm.patchValue({
      cryptoName: this.coinShortName,
      cryptoAmount: this.instantBuySellFee,
      fiatAmount: this.instantBuySellMaxAmt,
      fiatName: this.instantBuySellMinAmt
    })
  }
  patchBuyValue() {
    this.addSwatchesBuyForm.patchValue({
      cryptoName: this.coinShortName,
      cryptoAmount: this.instantBuySellFee,
      fiatAmount: this.instantBuySellMaxAmt,
      fiatName: this.instantBuySellMinAmt
    })
  }



  addBuyForm() {
    let url = `wallet/admin/set-instant-buy-min-max-(Amount-fee)?coinShortName=${this.addSwatchesBuyForm.value.cryptoName}&instantBuyFee=${this.addSwatchesBuyForm.value.cryptoAmount}&instantBuyMaxAmount=${this.addSwatchesBuyForm.value.fiatAmount}&instantBuyMinAmount=${this.addSwatchesBuyForm.value.fiatName}`
    this.commonService.showSpinner();
    this.commonService.post(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.hideSpinner();
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/instant-buy-sell-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.message)
    })
  }

  addSwatches() {
    let url = `wallet/admin/set-instant-buy-sell-min-max-(Amount-fee)?InstantBuySellFee=${this.addSwatchesForm.value.cryptoAmount}&coinShortName=${this.addSwatchesForm.value.cryptoName}&instantBuySellMaxAmt=${this.addSwatchesForm.value.fiatAmount}&instantBuySellMinAmt=${this.addSwatchesForm.value.fiatName}`
    this.commonService.showSpinner();
    this.commonService.post(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.hideSpinner();
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/instant-buy-sell-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(err.message)
    })
  }
}
