import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $: any
@Component({
  selector: 'app-tds-view',
  templateUrl: './tds-view.component.html',
  styleUrls: ['./tds-view.component.css']
})
export class TdsViewComponent implements OnInit {
  viewData: any
  email: any
  name: any
  tds: any
  status: any
  constructor(public activatedRoute: ActivatedRoute, public service: ServiceService, private router: Router) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.email = res.email;
      this.name = res.name;
      this.tds = res.tds;
      this.status = res.status
    })
  }

  ngOnInit() {
  }

}
