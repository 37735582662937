import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any
@Component({
    selector: 'app-hotcoldwallet-management',
    templateUrl: './hotcoldwallet-management.component.html',
    styleUrls: ['./hotcoldwallet-management.component.css']
})
export class HotcoldwalletManagementComponent implements OnInit {
    currTab: any;
    pageNumber: number = 1;
    totalItems: any;
    walletCheck: any = []
    hotCoin: any;
    addresObj: any = { address: '' }
    coldCoin: any;
    storgeID: any;
    coinListArr: any = []
    dataArr: any = []
    hotcoinname: any;
    hotcoinaddr: any;
    hotbalance: any;
    transferForm: FormGroup;
    hotTagId: any;
    coinlist: any = [];
    finalHotWalletArray: any = [];
    finalHotWalletTime: any;
    getAlltransactionsHistoryList: any = [];
    date: any;
    dateTime: any;
    time: any;

    tableForm: FormGroup;
    txnhasing: any;


    copyToClipboard(item) {

        this.service.toasterSucc('copied')
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (item));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
    }

    constructor(private router: Router, public service: ServiceService) { }

    ngOnInit() {
        this.tableForm = new FormGroup({
            txnhashing: new FormControl('')
        })
        this.myProfile()


        this.defaults();
        $("#emailid").on("keypress", function (e) {
            if (e.which === 32 && !this.value.length)
                e.preventDefault();
        });
        this.checkTransferFormValidations();

        this.getCoinWalletList();

        this.transferForm = new FormGroup({
            coldAddress: new FormControl('', [Validators.required]),
            transferrAmount: new FormControl('', [Validators.required]),
            transferAmount: new FormControl('', [Validators.required])


        })

    }
    twoFAType: any
    userEmail : any
    myProfile() {

        var url = 'account/my-account';
        this.service.showSpinner();
        this.service.get(url).subscribe((res: any) => {
            if (res['status'] == 200) {

                this.twoFAType = res.data.twoFaType;
                this.userEmail = res.data.email;


                this.service.hideSpinner();
            } else {
                this.service.hideSpinner();
                this.service.toasterErr(res['message']);
            }
        }, err => {
            if (err['status'] == '401') {
                this.service.onLogout();
                this.service.toasterErr('Unauthorized Access');
            } else {
                this.service.toasterErr('Something Went Wrong');
            }
        })
    }


    get txnhashing() { return this.tableForm.get('txnhashing') }

    getAlltransactionsHistory() {
        this.service.get('wallet/admin/transaction-history/get-all-transaction-history?page=0&pageSize=50&txnType=HOT_TO_COLD_TRANSFER').subscribe((res: any) => {
            if (res.status == 200) {
                this.getAlltransactionsHistoryList = res.data.resultlist;
            }
        })
    }
    getOtherList() {
        this.service.showSpinner();
        this.coinListArr.forEach(element => {
            this.service.get('wallet/admin/hot-cold-storage/get-storage-details-with-latestTime?coinName=' + (element)).subscribe((res: any) => {
                this.service.hideSpinner();
                if (res.status == 200) {
                    this.finalHotWalletArray.push(res);
                    console.log("-----------", this.finalHotWalletArray);
                }
                else {
                    this.service.hideSpinner();
                }
            }, (error) => {
                this.service.hideSpinner();
            })
            this.updateHotBalance(element) // for update wallet balance when we deposite from outside network
        });



    }
    getCoiList() {
        this.service.showSpinner();
        this.coinListArr.forEach(element => {
            this.service.get('wallet/admin/hot-cold-storage/get-storage-details-coin-hot?coin=' + (element) + '&storageType=HOT').subscribe((res: any) => {
                if (res.status == 200) {
                    this.finalHotWalletArray.push(res);
                    this.service.hideSpinner();
                }
                else {
                    this.service.hideSpinner();
                }
            }, (error) => {
                this.service.hideSpinner();
            })
        });
    }

    lastValueCoin: any
    getCoinWalletList() {
        this.service.showSpinner();
        this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
            this.service.hideSpinner()
            if (res.status == 200) {
                this.getAlltransactionsHistory();
                this.coinlist = res.data;
                this.lastValueCoin = this.coinlist[this.coinlist.length - 1];
                this.coinlist.forEach(element => {
                    this.coinListArr.push(element.coinShortName);
                });
                this.getOtherList();
            }
        }, error => {
            this.service.hideSpinner();
            if (error['status'] == 401) {
                this.service.onLogout();
                this.service.toasterErr('Unauthorized Access');
            }
            else {
                this.service.toasterErr('Something Went Wrong');
            }
        })
    }
    defaults() {
        this.currTab = 'HOT';

    }

    isValidDateTime(dateString) {

        const parsedDate = Date.parse(dateString);


        return !isNaN(parsedDate);
    }
    selectTab(tab) {
        this.currTab = tab;
        if (tab == 'HOT') {
            this.coinListArr = [];
            this.getCoinWalletList()
            this.finalHotWalletArray = []
            this.getOtherList()
        } else {
            this.getAlltransactionsHistory()
        }
    }
    updateAddressModal(coin) {
        this.coldCoin = coin

        $("#updateAddress1").modal({ backdrop: 'static', keyboard: false })
        this.addresObj.address = ""
    }
    createwalletCold(coin) {
        let data = {
            "coinName": coin,
            "storageType": this.currTab
        }
        this.service.post('wallet/admin/hot-cold-storage/create-storage-wallet', data).subscribe((res) => {
            this.service.showSpinner();
            if (res['status'] == 200) {
                this.walletCheck = res['data']

                if (this.currTab == 'COLD') {


                }
                this.service.toasterSucc(res['message'])
                this.service.hideSpinner();
            } else {
                this.service.toasterErr(res['message']);
                this.service.hideSpinner();
            }
        }, (err) => {
            this.service.hideSpinner();
        })
    }


    updateAddress(coin) {
        let data = {
            "coinName": coin,
            "coldAddress": 'COLD'
        }
        this.service.showSpinner();
        this.service.post('wallet/admin/hot-cold-storage/update-cold-storage-address', data).subscribe((res) => {
            this.service.hideSpinner();
            if (res['status'] == 200) {
                this.service.toasterSucc(res['message'])
                this.getCoinList();
            }
            else {
                this.service.toasterErr(res['message']);
                this.service.hideSpinner();
            }
        }, (err) => {
            this.service.hideSpinner();
        })
    }

    submitAddress() {
        if (this.addresObj.address == '') {
            this.service.toasterErr("Please Enter Address");
            return;
        }
        let data = {
            "coinName": this.coldCoin,
            "coldAddress": this.addresObj.address,
        }
        this.service.showSpinner();
        this.service.post('wallet/admin/hot-cold-storage/update-cold-storage-address', data).subscribe((res) => {
            this.service.hideSpinner();
            if (res['status'] == 200) {
                this.service.toasterSucc(res['message'])
                this.addresObj.address = ""
                this.getData()
            }
            else {
                this.service.toasterErr(res['message']);
                this.service.hideSpinner();
            }
        }, (err) => {
            this.service.hideSpinner();
        })
    }


    generateAddress(coin) {
        this.hotCoin = coin
        let data = {
            "accName": "harold",
            "coinName": this.hotCoin,
            "storageType": this.currTab
        }
        this.service.showSpinner();
        this.service.post('wallet/admin/hot-cold-storage/get-new-storage-address', data).subscribe((res) => {
            this.service.hideSpinner();
            if (res['status'] == 200) {
                this.service.toasterSucc(res['message'])
                this.getCoinList();
            }
            else {
                this.service.toasterErr(res['message']);
                this.service.hideSpinner();
            }
        }, (err) => {
            this.service.hideSpinner();
        })
    }

    updateHotBalance(coin) {
        let data = {
            "coinName": coin,
            "storageType": this.currTab
        }
        this.service.post('wallet/admin/hot-cold-storage/update-storage-wallet-balance', data).subscribe((res) => {
            if (res['status'] == 200) {
            }
            else {
            }
        }, (err) => {
        })
    }

    showDepositNetwork: boolean = false;
    transferBalance(coin, address, hotbaln, tagid) {


        this.hotcoinname = coin;
        this.hotcoinaddr = address;
        this.hotbalance = hotbaln;
        this.hotTagId = tagid;
        this.transferForm.patchValue({
            transferAmount: address
        })

        if (this.twoFAType == 'GOOGLE' || this.twoFAType == 'EMAIL') {
            if (this.twoFAType == 'GOOGLE') {
                $('#googleAuth').modal({ backdrop: 'static' });
            }
            if (this.twoFAType == 'EMAIL') {
                this.suggesstionFunc()

            }

        } else {
            this.service.showSpinner();
            if (coin == "BTC" || coin == "ETH" || coin == "BNB" || coin == "TRX" || coin == "USD"
                || coin == "DOT" || coin == "AVAX" || coin == "SOL" || coin == "INR") {
                this.showDepositNetwork = false;
            } else {
                this.showDepositNetwork = true;
            }
            setTimeout(() => {
                $('#transferModal').modal('show');
                this.service.hideSpinner();
            }, 2000);

        }


    }

    transferBalance2() {
        if (this.hotcoinname == "BTC" || this.hotcoinname == "ETH" || this.hotcoinname == "BNB" || this.hotcoinname == "TRX" || this.hotcoinname == "USD"
            || this.hotcoinname == "DOT" || this.hotcoinname == "AVAX" || this.hotcoinname == "SOL" || this.hotcoinname == "INR") {
            this.showDepositNetwork = false;
        } else {
            this.showDepositNetwork = true;
        }
        setTimeout(() => {
            $('#transferModal').modal('show');
            this.service.hideSpinner();
        }, 2000);
    }

    checkTransferFormValidations() {
        this.transferForm = new FormGroup({
            'coldAddress': new FormControl('', [Validators.required]),
            'transferrAmount': new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
            'transferAmount': new FormControl(''),
            'tag': new FormControl(''),
            'network': new FormControl('')
        })
    }


    transferHotToCold() {
        if (this.hotcoinname === 'XRP') {
            let data = {
                "amount": Number(this.transferForm.value.transferrAmount),
                "coinName": this.hotcoinname,
                "toAddress": this.transferForm.value.coldAddress,
                "tag": this.transferForm.value.tag
            }
            this.service.showSpinner();
            this.service.post('wallet/admin/hot-cold-storage/manual-transfer-hot-to-cold', data).subscribe((res) => {
                this.service.hideSpinner();
                if (res['status'] == 200) {
                    this.service.toasterSucc(res['message'])
                }

                else {
                    this.service.toasterErr(res['message']);
                    this.service.hideSpinner();
                }
            }, (err) => {
                this.service.hideSpinner();
            })
        }
        else if (this.hotcoinname === 'XLM') {
            let data = {
                "amount": Number(this.transferForm.value.transferrAmount),
                "coinName": this.hotcoinname,
                "toAddress": this.transferForm.value.coldAddress,
                "tag": this.transferForm.value.tag
            }
            this.service.showSpinner();
            this.service.post('wallet/admin/hot-cold-storage/manual-transfer-hot-to-cold', data).subscribe((res) => {
                this.service.hideSpinner();
                if (res['status'] == 200) {
                    this.service.toasterSucc(res['message'])
                }

                else {
                    this.service.toasterErr(res['message']);
                    this.service.hideSpinner();
                }
            }, (err) => {
                this.service.hideSpinner();
            })
        }
        else {
            if (this.showDepositNetwork && this.transferForm.value.network == '') {
                return this.service.toasterErr('Please select network.')
            }
            let data;
            if (this.hotcoinname == "BTC" || this.hotcoinname == "ETH" || this.hotcoinname == "BNB" || this.hotcoinname == "TRX" || this.hotcoinname == "USD"
                || this.hotcoinname == "DOT" || this.hotcoinname == "AVAX" || this.hotcoinname == "SOL" || this.hotcoinname == "INR") {
                data = {
                    "amount": Number(this.transferForm.value.transferrAmount),
                    "coinName": this.hotcoinname,
                    "toAddress": this.transferForm.value.coldAddress,
                }
            } else {
                data = {
                    "amount": Number(this.transferForm.value.transferrAmount),
                    "coinName": this.hotcoinname,
                    "toAddress": this.transferForm.value.coldAddress,
                    "network": this.transferForm.value.network
                }
            }
            this.service.showSpinner();
            this.service.post('wallet/admin/hot-cold-storage/manual-transfer-hot-to-cold', data).subscribe((res) => {
                this.service.hideSpinner();
                if (res['status'] == 200) {
                    this.service.toasterSucc(res['message'])
                }

                else {
                    this.service.toasterErr(res['message']);
                    this.service.hideSpinner();
                }
            }, (err) => {
                this.service.hideSpinner();
            })
        }

    }


    getCoinList() {
        this.coinListArr = []
        this.dataArr = []
        this.service.showSpinner();
        this.service.get('wallet/coin/get-coin-list').subscribe((res) => {
            this.service.hideSpinner();
            if (res['status'] == 200) {
                this.coinListArr = res['data']

                this.coinListArr.forEach(obj => {
                    let pushobj = {
                        coinShortName: obj.coinShortName,
                        address: '',
                        hotWalletBalance: '',
                        storageId: '',
                        create_wallet: true,
                        tagid: '',
                    }
                    this.dataArr.push(pushobj)
                })
                this.getData();

            } else {
                this.service.toasterErr(res['message']);
                this.service.hideSpinner();
            }
        }, (err) => {
            this.service.hideSpinner();
        })
    }

    getData() {

        this.service.showSpinner();
        this.service.get('wallet/admin/hot-cold-storage/get-storage-details?storageType=' + this.currTab).subscribe((succ) => {
            this.service.hideSpinner();
            if (succ['status'] == 200) {
                let tmpArr = succ['data'];


                for (let i = 0; i < this.dataArr.length; i++) {
                    let coinShortName = this.dataArr[i].coinShortName

                    let index_tmpArr = tmpArr.findIndex(x => x.coinType == coinShortName)
                    if (index_tmpArr != -1) {
                        this.dataArr[i].address = tmpArr[index_tmpArr].address
                        this.dataArr[i].hotWalletBalance = tmpArr[index_tmpArr].hotWalletBalance
                        this.dataArr[i].storageId = tmpArr[index_tmpArr].storageId
                        this.dataArr[i].create_wallet = false

                    }
                }

            }
        }, error => {
            this.service.hideSpinner();

        });
    }

    isAssending: boolean = true;
    sortAgent(key) {
        if (this.isAssending) {
            this.getAlltransactionsHistoryList = this.getAlltransactionsHistoryList.sort((a, b) => {
                return a[key] > b[key] ? 1 : -1;
            });
        } else {
            this.getAlltransactionsHistoryList = this.getAlltransactionsHistoryList.sort((a, b) => {
                return a[key] < b[key] ? 1 : -1;
            });
        }
    }


    storageUpdate(coin) {
        let data = {
            "coinName": coin,
            "storageType": 'HOT'
        }
        this.service.showSpinner();
        this.service.post('wallet/admin/hot-cold-storage/create-storage-wallet', data).subscribe((res) => {
            this.service.hideSpinner();
            if (res['status'] == 200) {
                this.service.toasterSucc(res['message'])
                this.getCoinList();
            }
            else {
                this.service.toasterErr(res['message']);
                this.service.hideSpinner();
            }
        }, (err) => {
            this.service.hideSpinner();
        })
    }
    newAddressGenerate(coin) {
        let data = {
            "accName": 1,
            "coinName": coin,
            "storageType": 'HOT'
        }
        this.service.showSpinner();
        this.service.post('wallet/admin/hot-cold-storage/get-new-storage-address', data).subscribe((res) => {
            this.service.hideSpinner();
            if (res['status'] == 200) {
                this.service.toasterSucc(res['message'])
                this.getCoinList();
            }
            else {
                this.service.toasterErr(res['message']);
                this.service.hideSpinner();
            }
        }, (err) => {
            this.service.hideSpinner();
        })
    }
    updateStorageBalance(coinName) {
        let data = {
            "coinName": coinName,
            "storageType": "HOT"
        }
        let url = `wallet/admin/hot-cold-storage/update-storage-wallet-balance`
        this.service.showSpinner();
        this.service.post(url, data).subscribe((res) => {
            this.service.hideSpinner();
            if (res['status'] == 200) {
                // this.service.toasterSucc(res['message'])
                this.getCoinList();
            }
            else {
                // this.service.toasterErr(res['message']);
                this.service.hideSpinner();
            }
        }, (err) => {
            this.service.hideSpinner();
        })
    }


    emailAuth
    smsAuth
    googleAuth
    getOtp(e) {
        this.emailAuth = e
        this.verifyGoogleAuth('email')
    }
    getOtpSMS(e) {
        this.smsAuth = e
        this.verifyGoogleAuth('sms')
    }
    getOtpGoogle(e) {
        this.googleAuth = e
        this.verifyGoogleAuth('Google')
    }
    suggesstionFunc() {



        // end email 2fa

        let url =
            "account/forget-password-mobile-app?email=" +
            this.userEmail;

        this.service.showSpinner();
        this.service.get(url).subscribe(
            (res) => {
                this.service.hideSpinner();
                if (res["status"] == 200) {
                    $("#suggest").modal("hide");
                    $("#emailAuth").modal("show");
                    this.service.toasterSucc(
                        "An OTP has been sent on your entered email ID"
                    );
                } else {
                    this.service.toasterErr(res["message"]);
                }
            },
            (err) => {
                this.service.hideSpinner();
                if (err["status"] == "500") {
                    this.service.toasterErr("Email is not verified");
                } else {
                    this.service.toasterErr(err["message"]);
                }
            }
        );


    }

    verifyGoogleAuth(select) {
        if (select == 'Google') {
            var apireq = {};
            var url = '';

            url = 'auth/verify-google';
            apireq = {
                "otp": this.googleAuth,
                //"secretKey": this.secretKey
            }

            this.service.showSpinner();
            this.service.post(url, apireq).subscribe(res => {
                this.service.hideSpinner();
                if (res['status'] == 200) {
                    this.emailAuth = ""
                    localStorage.removeItem('authToken')
                    if (this.twoFAType == 'GOOGLE') {

                    }
                    this.service.toasterSucc('Google Authentication Verified');
                    $('#googleAuth').modal('hide');

                }
                else {
                    this.service.toasterErr('Wrong Google Authentication Code');
                    this.googleAuth = ''
                    this.emailAuth = ""
                }
            }, err => {
                this.emailAuth = ""
                this.service.hideSpinner();
                this.service.toasterErr('Please wait for few minutes & reload the site.');
            })
        }

        else if (select == "email") {
            var emailapireq = {};
            var url = "";

            url = `auth/verify-Email-code`;
            emailapireq = {
                otp: this.emailAuth,
            };

            this.service.showSpinner();
            this.service.post(url, emailapireq).subscribe(
                (res) => {
                    this.service.hideSpinner();
                    if (res["status"] == 200) {
                        this.emailAuth = ""
                        $("#emailAuth").modal("hide");
                       
                     
                        if (this.twoFAType == "EMAIL"){
                            localStorage.setItem("Auth", res["data"]);
                        }
                        this.transferBalance2() 


                        this.service.toasterSucc(res["message"]);
                        
                    } else {
                        this.service.toasterErr(res["message"]);
                        this.emailAuth = ""
                    }
                },
                (err) => {
                    this.emailAuth = ""
                    this.service.hideSpinner();
                    this.service.toasterErr("Please wait for few minutes & reload the site.");
                }
            );
        }
    }
}
