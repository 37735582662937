import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any

@Component({
  selector: 'app-list-team',
  templateUrl: './list-team.component.html',
  styleUrls: ['./list-team.component.css']
})
export class ListTeamComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 20;
  currentPage = 1;
  totalItems: any;
  careerStatus: any
  contentId: any;
  teamId: any;
  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getEmbellishmentList();
  }

  openModal(careerStatus, teamId) {
    this.teamId = teamId;
    this.careerStatus = careerStatus;
    if (careerStatus == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getEmbellishmentList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  
  getEmbellishmentList() {
    let apiReqUrl: any = `static/get-all-career?page=0&pageSize=${this.itemPerPage}`
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }

  pagination(event) {
    this.currentPage = event;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/add-team'])
  }
  
  viewBody(teamId) {
    this.router.navigate(['/view-team'], { queryParams: { teamId: teamId } })
  }

  
  editBody(teamId) {
    this.router.navigate(['/edit-team'], { queryParams: { teamId: teamId } })
  }
  editCategory(contentId) {
    this.router.navigate(['/embellishment/edit-embellishment'])
  }
  blockUnblockUserModal() {

  }
  deleteCategoryModal(teamId) {
    $('#deleteCategory').modal('show')
    this.teamId = teamId
  }

  deleteCategory() {
    let apiReqUrl: any = "static/delete-careers-by-teamId?teamId=" + this.teamId
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

  performAction() {
    var url = 'static/block-or-unBlock-career?careerStatus=' + (this.careerStatus) + '&teamId=' + (this.teamId);
    this.commonService.showSpinner();
    this.commonService.post(url, '').subscribe(res => {

      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        if (this.careerStatus == 'BLOCK') {
          $('#block').modal('hide');
          this.commonService.toasterSucc('User Blocked Successfully');
          this.getEmbellishmentList()
        }
        else {
          $('#active').modal('hide');
          this.commonService.toasterSucc('User Activated Successfully');
          this.getEmbellishmentList()
        }
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
}
