import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { CustomOptInputComponent } from './otp-input/custom-opt-input/custom-opt-input.component';
import { BehaviorSubject } from 'rxjs';
declare var $
@Component({
  selector: 'otp-screen',
  templateUrl: './otp-manage.component.html',
  styleUrls: ['./otp-manage.component.css'],
})
export class OtpManageComponent implements OnInit {
  @ViewChild(CustomOptInputComponent, { static: true }) child: CustomOptInputComponent;
  @Output() getOtp = new EventEmitter
  @Output() resendOtp = new EventEmitter
  otp: any
  @Input() type: any
  @Input() showImage: any
  @Input() secretKey: any
  @Input() remainingTime: any
  @Input() isExpire: any
  mailVerifyLoader: boolean = false

  constructor(public service: ServiceService) { }

  ngOnInit() {
    this.getmessage()
  }
  message: any
  getmessage() {
    if (this.type == 'SMS') {
      this.message = 'registered mobile number'
    }
    if (this.type == 'Email') {
      this.message = 'registered email ID'
    }
    if (this.type == 'Google') {
      this.message = 'google authenticator'
    }
  }
  onInputChange(e) {

    this.otp = e
    console.log(e)
  }

  flag: boolean = true
  onSubmit() {
    this.mailVerifyLoader = true
    this.getOtp.emit(this.otp)


    setTimeout(() => {
      let status1 = $("#emailAuth").data('bs.modal') ? $("#emailAuth").data('bs.modal')._isShown : false
      let status2 = $("#smsAuth").data('bs.modal') ? $("#smsAuth").data('bs.modal')._isShown : false
      let status3 = $("#googleAuth").data('bs.modal') ? $("#googleAuth").data('bs.modal')._isShown : false
      let status4 = $("#emailAuths").data('bs.modal') ? $("#emailAuths").data('bs.modal')._isShown : false
      let status5 = $("#smsAuths").data('bs.modal') ? $("#smsAuths").data('bs.modal')._isShown : false
      let status6 = $("#googleAuths").data('bs.modal') ? $("#googleAuths").data('bs.modal')._isShown : false
      let status7 = $("#verifyWithdrawalModalId").data('bs.modal') ? $("#verifyWithdrawalModalId").data('bs.modal')._isShown : false
      let status8 = $("#emailAuthss").data('bs.modal') ? $("#emailAuthss").data('bs.modal')._isShown : false
      let status9 = $("#smsAuthss").data('bs.modal') ? $("#smsAuthss").data('bs.modal')._isShown : false
      let status10 = $("#googleAuthss").data('bs.modal') ? $("#googleAuthss").data('bs.modal')._isShown : false
      let status11 = $("#suggestNewID").data('bs.modal') ? $("#suggestNewID").data('bs.modal')._isShown : false
      let status12 = $("#newEmailVerify").data('bs.modal') ? $("#newEmailVerify").data('bs.modal')._isShown : false
      let status13 = $("#suggestNewIDPhone").data('bs.modal') ? $("#suggestNewIDPhone").data('bs.modal')._isShown : false
      let status14 = $("#newPhoneVerify").data('bs.modal') ? $("#newPhoneVerify").data('bs.modal')._isShown : false
      let finalStatus = status1 || status2 || status3 || status4 || status5 || status6 || status7 || status8 || status9 || status10 || status11 || status12 || status13 || status14
      if (!finalStatus) {
        this.flag = false
        setTimeout(() => {
          this.flag = true


        }, 500);
        this.child.resetOtp()
      }
    }, 100);

    setTimeout(() => {
      this.mailVerifyLoader = false
    }, 3000);
    this.isReset()
  }
  onSubmitResend() {
    this.resendOtp.emit(this.otp)
  }
  copyToClipboard(item) {
    this.service.toasterErr('Secret key copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  isReset(){
    this.service.isResetOtpItem.next(true)
   
  }
  close() {
    this.isReset()
    $('#emailAuth').modal('hide');
    $('#smsAuth').modal('hide');
    $('#googleAuth').modal('hide');
    $('#emailAuths').modal('hide');
    $('#smsAuths').modal('hide');
    $('#googleAuths').modal('hide');
    $('#verifyWithdrawalModalId').modal('hide');
    $('#emailAuthss').modal('hide');
    $('#smsAuthss').modal('hide');
    $('#googleAuthss').modal('hide');
    $('#suggestNewID').modal('hide');
    $('#newEmailVerify').modal('hide');
    $('#suggestNewIDPhone').modal('hide');
    $('#newPhoneVerify').modal('hide');
  }
}
