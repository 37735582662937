import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-commission-transaction-history',
  templateUrl: './commission-transaction-history.component.html',
  styleUrls: ['./commission-transaction-history.component.css']
})
export class CommissionTransactionHistoryComponent implements OnInit {
  minAge: Date;
  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: '', fromdate: '' }
  searchByEmail: any;
  coinSelected: any = '';

  walletWithdrawList: any = []
  fixedWithdrawList: any = []
  swapWithdrawList: any = []
  itemsPerPage = 20
  currentPage = 1
  totalItems: any

  currTab: any = 'WALLET';

  totalAmount: any = 0;
  totalWithdrawRequest: any = 0;

  constructor(public service: ServiceService, private datePipe: DatePipe, private router: Router) { }

  ngOnInit() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())
    this.getCoinList();
    this.getWalletList();
  }
  fromdate() {
    this.fromDate = new Date(this.calender.fromdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }
  getWalletList() {
    let apiReqUrl = `${'wallet/admin/transaction-history/get-all-transaction-history?page=' + (this.currentPage - 1) + '&pageSize=' + this.itemsPerPage +
      (this.searchByEmail ? ('&userEmail=' + this.searchByEmail) : '') + (this.coinSelected ? ('&coinName=' + this.coinSelected) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    this.service.showSpinner();
    this.service.get(apiReqUrl).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.walletWithdrawList = res['data'].resultlist
        this.totalItems = res['data'].totalCount;
      } else {
        this.walletWithdrawList = []
        this.totalItems = 0
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
    })
  }
  search() {
    if (this.searchByEmail || this.fromDate || this.twoDate || this.coinSelected) {
      this.getWalletList();
    }
  }

  reset() {
    if (this.searchByEmail || this.fromDate || this.twoDate || this.coinSelected) {
      this.searchByEmail = ''
      this.fromDate = ''
      this.twoDate = ''
      this.calender = { todate: '', fromdate: '' }
      this.coinSelected = ''
      this.getWalletList();
    }
  }

  pagination(page) {
    this.currentPage = page
    this.getWalletList();
  }

  coinList: any = []
  getCoinList() {
    this.service.showSpinner();
    this.service.get('wallet/coin/get-coin-list').subscribe((res) => {
      if (res['status'] == 200) {
        this.coinList = res['data']
        let removeCoin = ['INR']
        this.coinList = this.coinList.filter((ele) => {
          return !removeCoin.includes(ele.coinShortName)
        })
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
      }
    })
  }

  exportAsXLXS() {
    let dataArr = [];
    this.walletWithdrawList.forEach((element, index) => {
      dataArr.push({
        'S.No': index + 1,
        'Username': element.userName ? element.userName : '--',
        'Email': element.userEmail ? element.userEmail : '--',
        'Assets Type': element.coinType ? element.coinType : '--',
        'From': element.coinType ? element.coinType : '--',
        'To': element.swapType ? element.swapType : '--',
        'Trx Type': element.txnType ? element.txnType : '--',
        'Trx Hash': element.address ? element.address : '--',
        'Requested Date & Time': element.txnTime ? this.datePipe.transform(element.txnTime) : '--',
        'Amount': element.amount ? element.amount : '--',
        'Fee': element.coinAmount ? element.coinAmount : '--',
        'Status': element.status ? element.status : '--'
      })
    })
    this.service.exportAsExcelFile(dataArr, 'commission_transaction_history' + this.currTab.charAt(0).toUpperCase() + this.currTab.slice(1))
  }

  validateForm() {
    if (
      this.searchByEmail || this.fromDate || this.twoDate || this.coinSelected
    ) {
      return false
    }
    else {
      return true
    }

  }
  getTrxHashID(hash, id) {
    if (hash == null || hash == '') {
      return id
    } else {
      return hash
    }
  }

  copyToClipboard(item) {

    this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
}
