import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'app-view-fund-withdraw',
  templateUrl: './view-fund-withdraw.component.html',
  styleUrls: ['./view-fund-withdraw.component.css']
})
export class ViewFundWithdrawComponent implements OnInit {
  bank: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  userId: any
  complete: any
  pending: any
  reject: any
  utrNo: any
  fiatStatus: any
  amount: any
  fiatId: any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.bank = res.bankName
      this.userId = res.userId
      this.fiatStatus = res.fiatStatus
      this.utrNo = res.utrNo
      this.fiatId = res.fiatId
    })
    this.viewBlog()
  }
  approve() {
    let data = {}

    this.commonService.post(`wallet/admin/withdraw-Inr-Status?fiatStatus=${this.complete}&userId1=${this.userId}&amount=${this.amount}&id=${this.fiatId}`, data).subscribe((res) => {
      if (res['status'] == 200) {
        $('#approveModal').modal('hide')
        this.viewBlog()
      }
    })
  }

  approveKyc(status, amount) {
    this.amount = amount
    this.complete = status
    $('#approveModal').modal('show')
  }

  openModal(status) {
    this.pending = status
    $('#reject').modal('show')
  }

  rejectKyc(status) {
    this.reject = status
    $('#reject').modal('hide')
  }


  
  viewBlog() {
    let url = `wallet/admin/fiat-withdarw-view?fiatid=${this.fiatId}`
    this.commonService.showSpinner()
    this.commonService.get(url).subscribe((res: any) => {
      if (res.status = 200) {
        this.editData = res.data;
        this.commonService.hideSpinner()
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }
}
