import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-view-fund-upi',
  templateUrl: './view-fund-upi.component.html',
  styleUrls: ['./view-fund-upi.component.css']
})
export class ViewFundUpiComponent implements OnInit {
  upiId: any;
  skirtListArray: any;
  colorList : any = [];
  editData:any
  userId:any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.upiId =res.upiId
      this.userId = res.userId
    })
    this.viewBlog()
  }

  
 viewBlog(){
  let url = `wallet/admin/upi-list-view?upiId=${this.upiId}&userId=${this.userId}`
  this.commonService.showSpinner()
this.commonService.get(url).subscribe((res:any)=>{
  if (res.status=200) {
    this.editData=res.data;
    this.commonService.hideSpinner()

  }
 },err=>{

   this.commonService.hideSpinner();
   if(err['status']=='401'){
     this.commonService.toasterErr('Unauthorized Access');
   }else{
   this.commonService.toasterErr('Something Went Wrong');
}
 })

} 
}
