import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $: any
@Component({
  selector: 'app-instant-buy-sell-list',
  templateUrl: './instant-buy-sell-list.component.html',
  styleUrls: ['./instant-buy-sell-list.component.css']
})
export class InstantBuySellListComponent implements OnInit {


  transferForm: FormGroup;
  loopData: any = [];
  txndata: any = []
  lengthTotal: any;
  minDate: any;
  itemsPerPage = 20;
  currentPage: number = 1;
  totalItems: any
  flip: string = "List";
  FdListArray: any = []
  setInrFee: FormGroup;
  tabStatus: string = 'BUY'
  today: any = new Date().toISOString().slice(0, 16)

  constructor(
    private router: Router,
    public service: ServiceService,
    private activatedroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.checkTransferFormValidations();
    this.setInrFeeForm()
    this.getCoinList()
    this.adminFund()
  }

  buySellTab(tab) {
    this.tabStatus = tab;
  }

  copyToClipboard(item) {

    this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  isSearched: boolean = false;

  pagination(page) {
    this.currentPage = page;
    if (this.isSearched) {
      this.getCoinList()
    } else {
    }
  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl(''),
      'email': new FormControl(''),
      'cName': new FormControl(''),
      'ammount': new FormControl(''),
      'swapType': new FormControl(''),
      'txnType': new FormControl(''),
      'userName': new FormControl(''),
      'address': new FormControl('')

    })
  }

  setInrFeeForm() {
    this.setInrFee = new FormGroup({
      coinShortName: new FormControl('INR'),
      fiatFee: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      fiatMaxAmount: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      fiatMinAmount: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)])
    })
  }
  coinList: any = []
  getCoinList() {
    let url = `wallet/coin/get-coin-list`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.FdListArray = res.data
        this.service.hideSpinner();

      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    })
  }
  fundDataArray: any = []
  adminFund() {
    let url = `wallet/admin/admin-Fund-Amount`
    this.service.showSpinner()
    this.service.get(url).subscribe((res) => {

      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.fundDataArray = res['data'][0]
        this.setInrFee.patchValue({
          coinShortName: 'INR',
          fiatFee: this.fundDataArray.instantBuySellFee,
          fiatMaxAmount: this.fundDataArray.instantBuySellFeeMaxAmount,
          fiatMinAmount: this.fundDataArray.instantBuySellMinAmount
        })
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    })
  }
  action: any
  coinName: any
  openModal(action, coinName) {
    this.action = action;
    this.coinName = coinName
    if (action == 'DISABLE') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }

  performAction() {
    var url = 'wallet/coin/enable-disable?coinShortName=' + this.coinName + '&enableDisableStatus=' + this.action;
    this.service.showSpinner();
    this.service.post(url, '').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'DISABLE') {
          $('#block').modal('hide');
          this.service.toasterSucc('Coin disabled successfully');
        }
        else {
          $('#active').modal('hide');
          this.service.toasterSucc('Coin enabled successfully');
        }
        this.getCoinList();
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err.message)
    })
  }
  enableDisableBuy() {
    var url = 'wallet/coin/enable-disable-buy?coinShortName=' + this.coinName + '&enableDisableStatus=' + this.action;
    this.service.showSpinner();
    this.service.post(url, '').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'DISABLE') {
          $('#block').modal('hide');
          this.service.toasterSucc('Coin disabled successfully');
        }
        else {
          $('#active').modal('hide');
          this.service.toasterSucc('Coin enabled successfully');
        }
        this.getCoinList();
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err.message)
    })
  }

  setInrFeeFun() {
    let url = `wallet/admin/set-instant-buy-sell-min-max-(Amount-fee)-fiat?coinShortName=INR&fiatFee=${this.setInrFee.value.fiatFee}&fiatManAmount=${this.setInrFee.value.fiatMaxAmount}&fiatMinAmount=${this.setInrFee.value.fiatMinAmount}`
    this.service.showSpinner();
    this.service.post(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.adminFund();
        $('#InrModal').modal({ backdrop: 'static' });
        this.service.toasterSucc(res.message);

      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err.message)
    })
  }


}
