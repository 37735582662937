import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { filter } from 'minimatch';

declare var $: any
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  kycpendingListlength: any;
  coinList: any;
  totalUserCount: any;
  fDCount: any;
  swapCount: any;
  ticketCount: any;
  kycCount: any;
  aipCount: any;

  dashboardDetails: any;

  constructor(
    private service: ServiceService,
    private router: Router,
    private toastr: ToastrManager,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
  ) {

  }

  ngOnInit() {
    this.getUserList();
    this.getFDCountDetails()
    this.getSwapCountDetails()
    this.getTicketCountDetails();
    this.getKYCCountDetails();
    this.getAIPCountDetails()
  }
  getCountDetails() {
    this.service.get('account/admin/dashboard/dashboardApi').subscribe((res) => {
    })
  }
  getUserList() {
    var url = "account/admin/dashboard/dashboardApi";
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.getAllCoins();
        this.dashboardDetails = res['data'][0]
      } else {
        this.getAllCoins();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  getListOfKyc() {
    var url = 'account/admin/dashboard/pendingKycCount';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.kycpendingListlength = res['data'];
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }


  
  getAllCoins() {
    this.service.showSpinner();
    this.service.get('/wallet/admin/dashboard/get-deposit-and-coin-count').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinList = res['data'];
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.toasterErr('Unauthorized');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  getFDCountDetails() {
    this.service.get('/wallet/wallet/fd-count-details').subscribe(res => {
      if (res['status'] == 200) {
        this.fDCount = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
  
  getSwapCountDetails() {
    this.service.get('/wallet/wallet/swap-count-details').subscribe(res => {
      if (res['status'] == 200) {
        this.swapCount = res['data'];
      }
    }, err => {

      this.service.hideSpinner();

    })
  }
  
  getTicketCountDetails() {
    this.service.get('/static/ticket-count-details').subscribe(res => {
      if (res['status'] == 200) {
        this.ticketCount = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
  getKYCCountDetails() {
    this.service.get('/account/kyc-count-details').subscribe(res => {
      if (res['status'] == 200) {
        this.kycCount = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

  getAIPCountDetails() {
    this.service.get('/wallet/wallet/aip-count-details').subscribe(res => {
      if (res['status'] == 200) {
        this.aipCount = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
}
