import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
declare var $: any
@Component({
  selector: 'app-fd-list',
  templateUrl: './fd-list.component.html',
  styleUrls: ['./fd-list.component.css']
})
export class FdListComponent implements OnInit {
  selected: string = "btc";
  coinlist: any = [];
  coinListArr: any = [];
  transferForm: FormGroup;
  loopData: any = [];
  txndata: any = []
  lengthTotal: any;
  minDate: any;

  flip: string = "List";

  today: any = new Date().toISOString().slice(0, 16)

  fdListArray: any = [];
  itemsPerPage: number = 20;
  currentPage: number = 1;
  totalItems: any

  constructor(
    private router: Router,
    public service: ServiceService,
    private activatedroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.checkTransferFormValidations();
    this.getCoinWalletList();
    this.getFDList()
    this.getFDCountDetails()
  }

  copyToClipboard(item) {

    this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  isSearched: boolean = false;

  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl('')
    })
  }

  getFDList() {
    let url = `${'wallet/get-Fixed-Deposit-Details-by-pagination?page=' + (this.currentPage - 1) + '&pageSize=' + this.itemsPerPage
      + (this.transferForm.value.fromDate ? ("&fromDate=" + Date.parse(this.transferForm.value.fromDate)) : '')
      + (this.transferForm.value.toDate ? ("&toDate=" + Date.parse(this.transferForm.value.toDate)) : '')
      + (this.transferForm.value.coinNameSe ? ("&email=" + this.transferForm.value.coinNameSe) : '')
      + (this.transferForm.value.status ? "&fixedStatus=" + this.transferForm.value.status : '')}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.fdListArray = res.data.completeFixedDepositDetails || res.data.inProcessFixedDepositDetails || res.data.getEmailHistoryDetailsFromDate || res.data.getFixedHistoryDetails || [];
        this.totalItems = res['data'].allCountDetails || res['data'].completeCount || res['data'].inProcessCount || [];
        this.service.hideSpinner();
      } else {
        this.fdListArray = []
        this.totalItems = 0;
        this.service.hideSpinner();
      }
    }, (err: any) => {
      this.fdListArray = []
      this.totalItems = 0
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    });
  }

  pagination(page) {
    this.currentPage = page;
    this.getFDList()
  }

  searchEvent() {
    if (this.transferForm.value.fromDate || this.transferForm.value.toDate || this.transferForm.value.coinNameSe || this.transferForm.value.status) {
      this.currentPage = 1;
      this.getFDList();
    }
  }

  reset() {
    if (this.transferForm.value.fromDate || this.transferForm.value.toDate || this.transferForm.value.coinNameSe || this.transferForm.value.status) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        coinNameSe: "",
        status: "",
      });
      this.currentPage = 1;
      this.getFDList();
    }
  }

  getCoinWalletList() {
    this.service.showSpinner();
    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.coinlist = res.data;
        this.coinlist.forEach(element => {
          this.coinListArr.push(element.coinShortName);
        });
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
      if (error['status'] == 401) {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  exportAsXLSX() {
    let dataArr = [];
    this.fdListArray.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Customer Name": element.userName ? element.userName : '--',
        "Customer Email": element.email ? element.email : '',

        "Currency Type": element.coinName ? element.coinName : '--',
        "Principle Amt": element.coinAmount ? element.coinAmount : '--',
        "Start Date": element.createTime ? element.createTime.slice(0, 10) : '--',
        "End Date": element.payOutTime ? element.payOutTime.slice(0, 10) : '--',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Fixed Deposit list');
  }

  viewFd(fixedDepositId) {
    this.router.navigate(['/fd-view'], { queryParams: { fixedDepositId: fixedDepositId } })
  }

  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.fdListArray = this.fdListArray.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.fdListArray = this.fdListArray.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  validateForm() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.coinNameSe ||
      this.transferForm.value.status
    ) {
      return false
    }
    else {
      return true
    }

  }

  fDCount: any;
  getFDCountDetails() {
    this.service.get('/wallet/wallet/fd-count-details').subscribe(res => {
      if (res['status'] == 200) {
        this.fDCount = res['data'];
      }
    })
  }

}
