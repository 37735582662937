import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-community-edit',
  templateUrl: './community-edit.component.html',
  styleUrls: ['./community-edit.component.css']
})
export class CommunityEditComponent implements OnInit {
  addSwatchesForm: FormGroup
  imageUrl
  imageUrl1:any
  imageUrl2:any
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  communityId: any;
  currTab: any;

  constructor(private router: Router, public service: ServiceService, public active:ActivatedRoute,public sanitizer : DomSanitizer) {
    this.active.queryParams.subscribe((res:any)=>{
      this.communityId=res.communityId;
     
    })
   }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.viewWhitePaper();
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'language': new FormControl('', [Validators.required]),
      'link': new FormControl('',[Validators.required])
    })
  }
  addWhitePaper() {
    let data = {
      'linkName' : this.addSwatchesForm.value.language,
      'link' : this.addSwatchesForm.value.link
    }
    let url = `static/Update-Link?linkId=${this.communityId}`
    this.service.showSpinner();
    this.service.postApi(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.toasterSucc(res.message);
        this.service.hideSpinner();
        this.router.navigate(['/community-list'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    } , (err)=>{
      this.service.hideSpinner()
     })
  }

  viewWhitePaper(){
    let url = `static/get-Link-list-By-Id?linkId=${this.communityId}`
    this.service.showSpinner()
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.toasterSucc(res.message);
        this.addSwatchesForm.patchValue({
          "language" : res.data[0].linkName,
           "link" :  res.data[0].link
        })
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    },
    (err)=>{
      this.service.hideSpinner()
     })
  }

uploadImg(event,homeImage): void {
  var img = event.target.files[0];
  this.uploadImageFunc(img,homeImage);
}
uploadImageFunc(img,homeImage) {
  var fb = new FormData();
  fb.append('file', img)
  this.service.showSpinner();
  this.service.post('account/upload-file', fb).subscribe((res: any) => {
    if (res.status == 200) {
      if(homeImage == 'ENGLISH'){

        this.imageUrl = res['data'];
      }else if(homeImage == 'FRANCE'){

        this.imageUrl1 = res['data'];
      }else{
        this.imageUrl2 = res['data'];

      }
      this.service.hideSpinner();

    } else {
      this.service.hideSpinner();
    }
  },(err)=>{
       this.service.hideSpinner()
      }
  )
}



remove(e){
  this.imageUrl = ''
  if(e == 1){
    this.addSwatchesForm.get('image').reset()
  }
  else if(e == 2){
    this.addSwatchesForm.get('whitepaperImage').reset()
  }
  else if(e == 3){
    this.addSwatchesForm.get('whitepaperImageArabic').reset()

  }
}
 
}
