import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-view-admin',
  templateUrl: './view-admin.component.html',
  styleUrls: ['./view-admin.component.css']
})
export class ViewAdminComponent implements OnInit {
  userId: any;
  adminDetails: any;
  editStaffForm: FormGroup;
  newArr: any = [];

  constructor(public router: Router, public param: ActivatedRoute, public service: ServiceService) { 
    this.param.params.subscribe((res)=>{
      this.userId = res.id;
    })
  }

  ngOnInit() {
    this.editStaffForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      dashboardChecked: new FormControl(''),
      staffChecked: new FormControl(''),
      hotWalletChecked: new FormControl(''),
      kycChecked: new FormControl(''),
      walletChecked: new FormControl(''),
      staticChecked: new FormControl(''),
      logsChecked: new FormControl(''),
      ticketChecked: new FormControl(''),
      tradeChecked: new FormControl(''),
      disputeChecked: new FormControl(''),
      bankChecked: new FormControl(''),
      feeChecked: new FormControl('')
    })
    this.getProfile();
  }
  back() {
    this.router.navigate(['/admin-management'])
  }
  getProfile() {
    this.service.showSpinner();
    let dataa = {
      "primaryIdCommonPerRequest": this.userId
    }
    this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res)=>{
      this.adminDetails = res['data']['staffDetails']
      setTimeout(()=>{
        this.service.hideSpinner();
      },2000)
      
    })
  }
    
    checkboxClick(value, checked) {
      if (checked == true) {
        this.newArr.push(value);
      } else {
        let index = this.newArr.findIndex(x => x == value)
        this.newArr.splice(index, 1)
      }
    }

}
