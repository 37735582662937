import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-view-aip-detail',
  templateUrl: './view-aip-detail.component.html',
  styleUrls: ['./view-aip-detail.component.css']
})
export class ViewAipDetailComponent implements OnInit {
  contentId: any;
  skirtListArray: any;
  coinNamePercentage: any = [];
  coinNameDetails: any = []
  planId : any 
  totalLeft : number = 0
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService,private router : Router) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.planId = res.planId;
    })

   }

  ngOnInit() {
    this.viewAIP();
  }

  planDetail : any 
  viewAIP() {
    this.commonService.showSpinner()
    this.commonService.get("wallet/get-basket-list-of-admin?planId=" + this.planId).subscribe((res: any) => {
   
      if (res.status == 200) {
        this.planDetail = res.data['UserBasketCoin']
        this.coinNameDetails = res.data['CoinForAip'];
        this.coinNamePercentage = res.data['PercentageAipUSER'];
        this.commonService.hideSpinner()
      }
      else{
      this.commonService.hideSpinner();

      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })

  }


  addMoreCoins(){
    this.router.navigate(['/add-aip-percent'],{queryParams : {planId : this.planId,max:100 - this.totalLeft}})
  }
  editAIPCoins(){
    this.router.navigate(['/edit-aip-details'],{queryParams : {planId : this.planId}})
  }
  

}
