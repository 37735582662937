import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $: any;

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {
  currUrl: string;
  isLoggedIn: boolean;
  userDetail: any = {};
  userID: any;
  previlage: any = [];
  getperm: any;
  getrole: any;
  getpermission: any;

  role: string;
  flag: boolean = false;

  reset: boolean;

  constructor(private routes: Router, public service: ServiceService) {
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        if (this.service.isLoggedIn()) {
          if ((this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl == ``)) {
            this.routes.navigate([`/dashboard`])
          }
        } else {
          if (!(this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl.includes('reset-password') || this.currUrl == ``)) {
            this.routes.navigate([`/login`])
          }
        }
      }
    })
  }

  ngOnInit() {
    this.service.loginObs.subscribe(response => {
      if (response == 'login') {
        this.isLoggedIn = true;
        this.myProfile();
      }
      else {
        this.isLoggedIn = false;
      }
    })
    if (this.service.isLoggedIn()) {
      this.isLoggedIn = true;
      this.myProfile();
    } else {
      this.isLoggedIn = false;
    }
  }

  // get profile
  myProfile() {
    var url = `account/my-account`;
    this.service.get(url).subscribe(res => {
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        this.userID = res['data'].userId;
        this.previlage = res['data']['previlage'];
        this.role = res['data'].role;
        this.service.kycStatus.next(res['data']['platformKycStatus'])
        localStorage.setItem('userId', this.userID);
        localStorage.setItem('permission', this.previlage);
        localStorage.setItem('usertype', this.role);
        localStorage.setItem('adminEmail', this.userDetail.email)
        this.getrole = (localStorage.getItem('usertype'))
        this.getpermission = (localStorage.getItem('permission'))

        if (this.role == "SUBADMIN") {
          this.flag = true;
          this.navigation()
        }

      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access.');
      } else {
        this.service.toasterErr('Something Went Wrong.');
      }
    })
  }

  navigation() {
    for (let item of this.previlage) {
      if (item == 'DASHBOARD') {
        this.routes.navigate(['/dashboard'])
        break;
      }
      else if (item == 'USER_MANAGEMENT') {
        this.routes.navigate(['/user-management'])
        break;
      }
      else if (item == 'STAFF_MANAGEMENT') {
        this.routes.navigate(['/staff-management'])
        break;
      }
      else if (item == 'WALLET_MANAGEMENT') {
        this.routes.navigate(['/wallet-management'])
        break;
      }
      else if (item == 'HOT_COLD_LIMIT_MANAGEMENT') {
        this.routes.navigate(['/hotcoldwallet-management'])
        break;
      }
      else if (item == 'KYC_MANAGEMENT') {
        this.routes.navigate(['/kyc-management'])
        break;
      }
      else if (item == 'FD_MANAGEMENT') {
        this.routes.navigate(['/fd-list'])
        break;
      }
      else if (item == 'INSTANT_SWAP_MANAGEMENT') {
        this.routes.navigate(['/instant-list'])
        break;
      }
      else if (item == 'AIP_MANAGEMENT') {
        this.routes.navigate(['/list-aip'])
        break;
      }
      else if (item == 'FEE_MANAGEMENT') {
        this.routes.navigate(['/fee-manage-new'])
        break;
      }
      else if (item == 'FUND_MANAGEMENT') {
        this.routes.navigate(['/list-fund'])
        break;
      }
      else if (item == 'TRX_MANAGEMENT') {
        this.routes.navigate(['/trx-manages'])
        break;
      }
      else if (item == 'SETTLEMENT_MANAGEMENT') {
        this.routes.navigate(['/settlement-management'])
        break;
      }
      else if (item == 'REFERRAL_MANAGEMENT') {
        this.routes.navigate(['/referal-list'])
        break;
      }
      else if (item == 'COMMISSION_MANAGEMENT') {
        this.routes.navigate(['/commission-management'])
        break;
      }
      else if (item == 'TICKET_MANAGEMENT') {
        this.routes.navigate(['/ticket-management'])
        break;
      }
      else if (item == 'BANK_MANAGEMENT') {
        this.routes.navigate(['/bank-management'])
        break;
      }
      else if (item == 'TDS_MANAGEMENT') {
        this.routes.navigate(['/tds'])
        break;
      }
      else if (item == 'STATIC_CONTENT') {
        this.routes.navigate(['/statics-content'])
        break;
      }
      else if (item == 'ACADEMY_MANAGEMENT') {
        this.routes.navigate(['/list-academy'])
        break;
      }
      else if (item == 'FAQ_MANAGEMENT') {
        this.routes.navigate(['/faq-management'])
        break;
      }
      else if (item == 'LOGS_MANAGEMENT') {
        this.routes.navigate(['/logs-management'])
        break;
      }
      else if (item == 'TEAMS_MANAGEMENT') {
        this.routes.navigate(['/list-team'])
        break;
      }
      else if (item == 'NEWS_MANAGEMENT') {
        this.routes.navigate(['/news-list'])
        break;
      }
      else if (item == 'SETTINGS') {
        this.routes.navigate(['/setting'])
        break;
      }
      else if (item == 'TRADE_HISTORY') {
        this.routes.navigate(['/trading-history'])
        break;
      }

    }

  }

  // logout
  logoutModalOpen() {
    $('#logoutModal').modal('show');
  }

  onLogout() {
    $('#logoutModal').modal('hide');
    this.service.onLogout();
    window.location.reload();

  }

  ready() {
    $("#toggle_Menu").click(function () {
      $(".body-class").toggleClass("toggle-wrapper")
    });
  }


}
