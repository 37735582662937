import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { element } from 'protractor';
@Component({
  selector: 'app-add-exec-base-coin',
  templateUrl: './add-exec-base-coin.component.html',
  styleUrls: ['./add-exec-base-coin.component.css']
})
export class AddExecBaseCoinComponent implements OnInit {

  selectedItems = [];
  dropdownList = [
  ];

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'coinShortName',
    textField: 'coinShortName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: false,
    enableCheckAll: false,
  };

  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  planId: any;
  productForm: FormGroup;

  aipPerList: any = [];
  flag: boolean = false
  max = 80
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public commonService: ServiceService, private fb: FormBuilder) {

    this.productForm = this.fb.group({
      name: '',
      quantities: this.fb.array([]),
    });
    this.activatedRoute.queryParams.subscribe((res) => {
      this.planId = res.planId
      this.flag = res.flag
      this.max = res.max
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getCoinList()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'baseCoin': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
    })
  }
  set_max(me: number) {
    me = Number(me)
    if (me > this.max) {
      setTimeout(() => {
        this.addSwatchesForm.patchValue({
          percent: this.max
        })
      }, 100);
    }
  }
  coinDataArray: any = []
  coinList: any = []
  getCoinList() {
    let url = `wallet/coin/get-coin-list`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.coinDataArray = res.data
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

  getResponse(event) {
    let selectedCoinName = event.target.value
    this.dropdownList = this.coinDataArray.filter(x => x.coinShortName != selectedCoinName)

  }

  onItemSelect(item: any) {
  }


  removeCoin(i, coinShortName) {
    this.dropdownList.splice(i, 1)
    coinShortName['isSelected'] = false

  }
  totalPercentage: number = 0
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  navigateBack() {
    if (this.flag) {
      this.router.navigate(['/edit-aip-details'], { queryParams: { planId: this.planId } })

    }
    else {
      this.router.navigate(['/list-swap-coin'], { queryParams: { planId: this.planId } })

    }
  }


  quantities(): FormArray {
    return this.productForm.get("quantities") as FormArray
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      qty: '',
      price: '',
    })
  }

  addQuantity() {
    this.quantities().push(this.newQuantity());
  }

  removeQuantity(i: number) {
    this.quantities().removeAt(i);
  }

  onSubmit() {
  }
  addSwatches() {
    let data = this.selectedItems.map(x => { return x.coinShortName })
    let url = `wallet/admin/add-exCoin-for-swap?baseCoin=${this.addSwatchesForm.value.baseCoin}`

    this.commonService.postApi(url, data).subscribe((res) => {
      if (res['status'] == '200') {
        this.commonService.hideSpinner();
        this.router.navigate(['/list-swap-coin'])
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
}
