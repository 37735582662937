import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
// declare var $: any

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  userDetail: any = {};

  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.myProfile();
  }

  // get profile
  myProfile() {
    var url = 'account/my-account';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      if (res['status'] == 200) {
        this.userDetail = res['data']
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // navigate to change password
  changePassword() {
    this.router.navigate(['/change-password']);
  }

}
