import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-view-faq',
  templateUrl: './view-faq.component.html',
  styleUrls: ['./view-faq.component.css']
})
export class ViewFaqComponent implements OnInit {
  viewFaqForm:FormGroup;
  pageNumber:number=1
  faqId: any;
  editData: any;
  currTab: any;
  constructor(public route:Router,public service:ServiceService, public active:ActivatedRoute) { 
    this.active.queryParams.subscribe((res:any)=>{
      this.faqId=res.faqId;
      this.currTab=res.lan;  
    })
  }

  ngOnInit(): void {
      this.viewFaqForm= new FormGroup({
        'title':new FormControl('', [Validators.required,Validators.pattern(/^[^0-9!@#$%^*()_+|<>,;'"]*$/)]),
        'description': new FormControl('', [Validators.required,Validators.pattern('')])
      })
      this.editFaq()
  }


  editFaqLanguage(){
    if(this.currTab=='English'){
      this.editFaq();
    }
  }
  
  
  editFaq(){
    
   this.service.get("/static/admin/static-content/get-faq-question-answer-by-Id?faqId="+this.faqId).subscribe((res:any)=>{
     if (res.status=200) {
       this.editData=res.data;
     }
    },err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Something Went Wrong');
   }
    })

  } 

  } 

