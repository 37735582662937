import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-blog-category-list',
  templateUrl: './blog-category-list.component.html',
  styleUrls: ['./blog-category-list.component.css']
})
export class BlogCategoryListComponent implements OnInit {

  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  bannerId: any;
  careerStatus: string;
  contentId: any;

  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.getBlogList();
  }


  openModal(careerStatus, contentId) {
    this.contentId = contentId;
    this.careerStatus = careerStatus;
    if (careerStatus == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }

  getBlogList() {
    let apiReqUrl: any = `${'static/admin/static-content/get-Blog-list?page=' + (this.currentPage - 1) + '&pageSize=' + this.itemPerPage}`
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data.list ? res.data.list : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
    })
  }
  pagination(event) {
    this.currentPage = event;
    this.getBlogList()
  }
  addBlog() {
    this.router.navigate(['/add-blog'])
  }
  viewBlog(contentId) {
    this.router.navigate(['/view-blog'], { queryParams: { contentId: contentId } })
  }
  editBlog(contentId) {
    this.router.navigate(['/blog-category-edit'], { queryParams: { contentId: contentId } })
  }

  deleteBlogModal(contentId) {
    $('#deleteBlogId').modal('show')
    this.contentId = contentId
  }
  deleteBlog() {
    let apiReqUrl: any = "static/admin/static-content/delete-Blog-by-id?blogId=" + this.contentId
    this.commonService.showSpinner();
    this.commonService.post(apiReqUrl, {}).subscribe((res: any) => {
      $('#deleteBlogId').modal('hide');
      if (res.status == 200) {
        this.getBlogList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

}
